.page-header {
  margin-bottom: 1rem;

  &.is-align-center {
    text-align: center;
    .page-header_title {
      text-align: center;
      justify-content: center;
    }
  }

  .page-header_beacon {
    margin-left: 0.5rem;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }


  &_middle {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    &--left,
    &--right {
      display: flex;
    }

    &--left {
      flex-direction: column;
      flex: 1;

      .page-header_title {
        display: flex;
        align-items: center;
        margin: 0.75rem 1rem 0.75rem 0;

        .icon.material-symbols-outlined {
          font-size: 32px;
          padding-right: 0.25rem;
        }
      }

      p.page-header_desc {
        padding-top: 0.5rem;
      }
    }

    &--right {
      align-self: flex-end;
      gap: 0.5rem;
    }
  }

  &_bottom {
    margin-top: 1rem;
  }

  &_middle--left:not(.has-desc) ~ .page-header_middle--right {
    align-self: center;
  }
}

.game-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &_details {
    padding: 20px;
  }

  &_team {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 20px;
    
    &--opponent {
      .game-header_team-details {
        justify-content: end;
      }
    }
  }

  &_team-details {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &_score {
    font-size: 32px;
    font-weight: bold;
  }

  &_team-name {
    padding: 0 20px;
    font-size: 18px;
    font-weight: 600;
  }
}

#user-menu.p-overlaypanel .p-overlaypanel-content {
  padding: 0;
}