label,
.form-group > label.form-label {
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.175rem;

  .form-label--right {
    margin-left: auto;
    color: var(--text-color-secondary);
    font-weight: 700;
  }
}

.form-group > .help-label {
  display: block;
  color: var(--text-color-secondary);
  margin-top: 0.175rem;
}

.form-group > {
  label > .help-tip {
    display: flex;

    .icon {
      margin-left: 0.125rem;
    }
  }
}