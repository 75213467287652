.entity-switcher {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  border-radius: $radius-small;
  border: none;
  box-shadow: none;
  background-color: transparent; 
  overflow: hidden;
  cursor: pointer;

  &:hover,
  &.p-inputwrapper-focus {
    background-color: var(--surface-100);
    box-shadow: var(--shadow-1);
  }

  .p-dropdown-label {
    padding: 0;
  }

  .p-dropdown-trigger {
    overflow: hidden;
    transition: opacity $duration-normal, width $duration-normal;;

    .layout-sidebar-collapsed .layout-sidebar & {
      opacity: 0;
      width: 0;
    }
  }

  .p-avatar {
    vertical-align: top;
  }
}

// remove padding-left whilst menuitem-row is a parent
.p-menuitem-row .p-submenu-header {
  padding-left: 0;
}