

.image-grid {
    display: grid;
    gap: 4px;

    .image-grid-item {
        overflow: hidden;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        vertical-align: top;
    }
  
    &-1 {
        .image-grid-item {
            max-height: calc(100vw * (16 / 9)); // Max height based on 9:16 ratio
        }
    }
  
    &-2 {
        grid-template-columns: 1fr 1fr;
  
        .image-grid-item {
            img {
                aspect-ratio: 1 / 1;
            }
        }
    }
  
    &-3 {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
  
        .image-grid-item {
            &:nth-child(1) {
                grid-column: 1 / -1;

                img {
                    aspect-ratio: 16 / 9; 
                }
            }
  
            &:nth-child(2),
            &:nth-child(3) {
                img {
                    aspect-ratio: 4 / 3; 
                }
            }
        }
    }
  
    &-4 {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
  
        .image-grid-item {
            &:nth-child(1) {
                grid-column: 1 / -1;

                img {
                    aspect-ratio: 16 / 9;
                }
            }
  
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                img {
                    aspect-ratio: 1 / 1; 
                }
            }
        }
    }
  
    &-5 {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto auto;
  
        .image-grid-item {
            &:nth-child(1),
            &:nth-child(2) {
                grid-column: auto / span 3;

                img {
                    aspect-ratio: 4 / 3; // Top row images
                }
            }
  
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                grid-column: auto / span 2;
                
                img {
                    aspect-ratio: 1 / 1; // Bottom row images
                }
            }
        }
    }
}