// Typography

$family-primary: "Inter", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$family-secondary: "Barlow", "Inter", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$family-tertiary: "Barlow Semi Condensed", "Barlow", "Inter", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$family-serif: "Lora", "Times New Roman", "Times", "Baskerville", "Georgia", serif !default;
$family-monospace: monospace !default;
$render-mode: optimizeLegibility !default;

$size-1: 0.625rem !default; // 10px
$size-2: 0.75rem !default; // 12px
$size-3: 0.875rem !default; // 14px
$size-4: 1rem !default; // 16px
$size-5: 1.125rem !default; // 20px
$size-6: 1.5rem !default; // 24px
$size-7: 2rem !default; // 32px
$size-8: 2.5rem !default; //40px
$size-9: 3.5rem !default; // 56px
$size-10: 4.5rem !default; // 60px

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;
$weight-heavy: 800 !default;
$weight-black: 900 !default;

$letter-spacing-tiny: -0.05em !default;
$letter-spacing-small: -0.01em !default;
$letter-spacing-normal: 0 !default;
$letter-spacing-medium: 0.01em !default;
$letter-spacing-large: 0.05em !default;
$letter-spacing-xlarge: 0.15em !default;
$letter-spacing-xxlarge: 0.25em !default;