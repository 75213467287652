/// Padding of a button, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
/// @group button
$buttonPadding: .7rem 1.25rem;

/// Width of a button having an icon with no label
/// @group button
$buttonIconOnlyWidth: 2.357rem;

/// Padding of a button having an icon with no label
/// @group button
$buttonIconOnlyPadding: .5rem 0;

/// Background of a button
/// @group button
$buttonBg: var(--primary);

/// Text color of a button
/// @group button
$buttonTextColor: var(--primary-invert);

/// Border of a button
/// @group button
$buttonBorder: 1px solid var(--primary);

/// Background of a button in hover state
/// @group button
$buttonHoverBg: var(--primary-hover);

/// Text color of a button in hover state
/// @group button
$buttonTextHoverColor: var(--primary-invert);

/// Border color of a button in hover state
/// @group button
$buttonHoverBorderColor: var(--primary-hover);

/// Background of a button in pressed state
/// @group button
$buttonActiveBg: var(--primary-active);

/// Color of a button in pressed state
/// @group button
$buttonTextActiveColor: var(--primary-invert);

/// Border color of a button in pressed state
/// @group button
$buttonActiveBorderColor: var(--primary-active);

/// Shadow of a raised button
/// @group button
$raisedButtonShadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

/// Border radius of a rounded button
/// @group button
$roundedButtonBorderRadius: 2rem;

/// Alpha level of a text button background in hover state
/// @group button
$textButtonHoverBgOpacity: .1;

/// Alpha level of a text button background in active state
/// @group button
$textButtonActiveBgOpacity:.16;

/// Border style of a outlined button
/// @group button
$outlinedButtonBorder:1px solid;

/// Text color of a plain text button
/// @group button
$plainButtonTextColor: var(--text-color-strong);

/// Background color of a plain text button in hover state
/// @group button
$plainButtonHoverBgColor: var(--opaque-50);

/// Background color of a plain text button in active state
/// @group button 
$plainButtonActiveBgColor: var(--grey-300);

/// Background of a secondary button
/// @group button
$secondaryButtonBg: var(--grey-300);

/// Text color of a secondary button
/// @group button
$secondaryButtonTextColor: var(--text-color-strong);

/// Border of a secondary button
/// @group button
$secondaryButtonBorder: 0px solid var(--grey-300);

/// Background of a secondary button in hover state
/// @group button
$secondaryButtonHoverBg: var(--grey-400);

/// Text color of a secondary button in hover state
/// @group button
$secondaryButtonTextHoverColor: var(--text-color-strong);

/// Border color of a secondary button in hover state
/// @group button
$secondaryButtonHoverBorderColor: var(--grey-400);

/// Background of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBg: var(--grey-400);

/// Text color of a secondary button in pressed state
/// @group button
$secondaryButtonTextActiveColor: var(--text-color-strong);

/// Border color of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBorderColor: var(--grey-400);

/// Box shadow of a secondary button in focused state
/// @group button
$secondaryButtonFocusShadow: 0 0 0 0.2rem var(--focus-color-opaque);

/// Background of an info button
/// @group button
$infoButtonBg: var(--info);

/// Text color of an info button
/// @group button
$infoButtonTextColor: var(--info-invert);

/// Border of an info button
/// @group button
$infoButtonBorder: 1px solid var(--info);

/// Background of an info button in hover state
/// @group button
$infoButtonHoverBg: var(--info-hover);

/// Text color of an info button in hover state
/// @group button
$infoButtonTextHoverColor: var(--info-invert);

/// Border color of an info button in hover state
/// @group button
$infoButtonHoverBorderColor: var(--info-hover);

/// Background of an info button in pressed state
/// @group button
$infoButtonActiveBg: var(--info-active);

/// Text color of an info button in pressed state
/// @group button
$infoButtonTextActiveColor: var(--info-invert);

/// Border color of an info button in pressed state
/// @group button
$infoButtonActiveBorderColor:var(--info-active);

/// Box shadow of an info button in focused state
/// @group button
$infoButtonFocusShadow: 0 0 0 0.2rem var(--info-opaque);

/// Background of a success button
/// @group button
$successButtonBg: var(--success);

/// Text color of a success button
/// @group button
$successButtonTextColor: var(--success-invert);

/// Border of a success button
/// @group button
$successButtonBorder: 1px solid var(--success);

/// Background of a success button in hover state
/// @group button
$successButtonHoverBg: var(--success-hover);

/// Text color of a success button in hover state
/// @group button
$successButtonTextHoverColor: var(--success-invert);

/// Border color of a success button in hover state
/// @group button
$successButtonHoverBorderColor: var(--success-hover);

/// Background of a success button in pressed state
/// @group button
$successButtonActiveBg: var(--success-active);

/// Text Color of a success button in pressed state
/// @group button
$successButtonTextActiveColor: var(--success-invert);

/// Border color of a success button in pressed state
/// @group button
$successButtonActiveBorderColor: var(--success-active);

/// Box shadow of a success button in focused state
/// @group button
$successButtonFocusShadow: 0 0 0 0.2rem var(--success-opaque);

/// Background of a warning button
/// @group button
$warningButtonBg: var(--warning);

/// Text color of a warning button
/// @group button
$warningButtonTextColor: var(--warning-invert);

/// Border of a warning button
/// @group button
$warningButtonBorder: 1px solid var(--warning);

/// Background of a warning button in hover state
/// @group button
$warningButtonHoverBg: var(--warning-hover);

/// Text color of a warning button in hover state
/// @group button
$warningButtonTextHoverColor: var(--warning-invert);

/// Border color of a warning button in hover state
/// @group button
$warningButtonHoverBorderColor: var(--warning-hover);

/// Background of a warning button in pressed state
/// @group button
$warningButtonActiveBg: var(--warning-active);

/// Text color of a warning button in pressed state
/// @group button
$warningButtonTextActiveColor: var(--warning-invert);

/// Border color of a warning button in pressed state
/// @group button
$warningButtonActiveBorderColor: var(--warning-active);

/// Box shadow of a warning button in focused state
/// @group button
$warningButtonFocusShadow: 0 0 0 0.2rem var(--warning-opaque);

/// Background of a help button
/// @group button
$helpButtonBg: var(--info);

/// Text color of a help button
/// @group button
$helpButtonTextColor: var(--info-invert);

/// Border of a help button
/// @group button
$helpButtonBorder:1px solid var(--info);

/// Background of a help help in hover state
/// @group button
$helpButtonHoverBg: var(--info-hover);

/// Text color of a help button in hover state
/// @group button
$helpButtonTextHoverColor: var(--info-invert);

/// Border color of a help button in hover state
/// @group button
$helpButtonHoverBorderColor: var(--info-hover);

/// Background of a help button in pressed state
/// @group button
$helpButtonActiveBg: var(--info-active);

/// Text color of a help button in pressed state
/// @group button
$helpButtonTextActiveColor: var(--info-invert);

/// Border color of a help button in pressed state
/// @group button
$helpButtonActiveBorderColor: var(--info-active);

/// Box shadow of a help button in focused state
/// @group button
$helpButtonFocusShadow:0 0 0 0.2rem var(--info-opaque);

/// Background of a danger button
/// @group button
$dangerButtonBg: var(--danger);

/// Text color of a danger button
/// @group button
$dangerButtonTextColor: var(--danger-invert);

/// Border of a danger button
/// @group button
$dangerButtonBorder: 1px solid var(--danger);

/// Background of a danger button in hover state
/// @group button
$dangerButtonHoverBg: var(--danger-hover);

/// Text color of a danger button in hover state
/// @group button
$dangerButtonTextHoverColor: var(--danger-invert);

/// Border color of a danger button in hover state
/// @group button
$dangerButtonHoverBorderColor: var(--danger-hover);

/// Background of a danger button in pressed state
/// @group button
$dangerButtonActiveBg: var(--danger-active);

/// Text color of a danger button in pressed state
/// @group button
$dangerButtonTextActiveColor: var(--danger-invert);

/// Border color of a danger button in pressed state
/// @group button
$dangerButtonActiveBorderColor: var(--danger-active);

/// Box shadow of a danger button in focused state
/// @group button
$dangerButtonFocusShadow: 0 0 0 0.2rem var(--danger-opaque);

/// Text color of a link button
/// @group button
$linkButtonColor: var(--primary);

/// Text color of a link button in hover state
/// @group button
$linkButtonHoverColor: var(--primary-hover);

/// Text decoration of a link button in hover state
/// @group button
$linkButtonTextHoverDecoration:underline;

/// Box shadow of a link button in focused state
/// @group button
$linkButtonFocusShadow: 0 0 0 0.2rem var(--primary-opaque);

/// Background of a toggle button
/// @group button
$toggleButtonBg: var(--surface-0);

/// Border of a toggle button
/// @group button
$toggleButtonBorder: 1px solid var(--border-color);

/// Text color of a toggle button
/// @group button
$toggleButtonTextColor: $textColor;

/// Icon color of a toggle button
/// @group button
$toggleButtonIconColor: $textSecondaryColor;

/// Background of a toggle button in hover state
/// @group button
$toggleButtonHoverBg: var(--surface-200);

/// Border color of a toggle button in hover state
/// @group button
$toggleButtonHoverBorderColor: var(--border-color-hover);

/// Text color of a toggle button in hover state
/// @group button
$toggleButtonTextHoverColor: $textColor;

/// Icon color of a toggle button in hover state
/// @group button
$toggleButtonIconHoverColor: $textSecondaryColor;

/// Background of a toggle button in selected state
/// @group button
$toggleButtonActiveBg: $primaryColor;

/// Border color of a toggle button in selected state
/// @group button
$toggleButtonActiveBorderColor: $primaryColor;

/// Text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveColor: var(--primary-invert);

/// Icon color of a toggle button in selected state
/// @group button
$toggleButtonIconActiveColor: var(--primary-invert);

/// Hover background of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBg: $primaryDarkColor;

/// Hover border color of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBorderColor: $primaryDarkColor;

/// Hover text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveHoverColor: var(--primary-invert);

/// Hover icon of a toggle button in selected state
/// @group button
$toggleButtonIconActiveHoverColor: var(--primary-invert);

/// Width of a floating action button on speed dial
/// @group button
$speedDialButtonWidth: 4rem;

/// Height of a floating action button on speed dial
/// @group button
$speedDialButtonHeight: 4rem;

/// Font Size of a floating action button on speed dial
/// @group button
$speedDialButtonIconFontSize: 1.3rem;

/// Width of any action button on speed dial
/// @group button
$speedDialActionWidth: 3rem;

/// Height of any action button on speed dial
/// @group button
$speedDialActionHeight: 3rem;

/// Background color of any action button on speed dial 
/// @group button
$speedDialActionBg: #495057;

/// Background color of any action button on speed dial in hover state
/// @group button
$speedDialActionHoverBg: #343a40;

/// Color of any action button on speed dial 
/// @group button
$speedDialActionTextColor: #fff;

/// Color of any action button on speed dial in hover state
/// @group button
$speedDialActionTextHoverColor: #fff;