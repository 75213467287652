.section-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;


  &_inner {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &_title {
    margin: 0;
    font-size: 1.75rem;
    line-height: 1.5;
    font-weight: 500;
    color: var(--text-color-strong);
  }

  &_subtitle {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 1.125rem;
    color: var(--text-color);
  }

  &_actions {
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
  }
}