.threshold {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;

  &-value {
    font-weight: 500;
  }

  &--active {
    .threshold-icon {
      color: var(--green-500);
    }
  }

  &--rest {
    .threshold-icon {
      color: var(--red-500);
    }
  }
}

.threshold-list {
  display: inline-flex;
  flex-direction: column;

  .threshold-icon {
    width: 40px;
  }
}

.threshold-inputs {
  display: inline-flex;
  flex-direction: column;

  .p-inputgroup-addon {
    padding: 0;
    height: 45px;
  }

  .p-calendar {
    width: 75px;

    .p-inputtext {
      text-align: center;
    }
  }
}