.addto-collections-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  
  .addto-collections-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > div {
      display: flex;
      gap: 0.5rem;

      label {
        flex: 1;
      }
    }
  }

  .p-tree {
    flex: 1;
  }

  .p-button {
    flex: none;
    margin-top: 1rem;
  }
}