.details-list {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;

  &_item {
    border-top-width: 1px;
    border-top-style: solid;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    border-color: var(--surface-200);
  }

  &_title {
    width: 16.6667%;
    font-weight: 500;
    color: var(--surface-500);
  }

  &_caption {
    width: 66.6667%;
    color: var(--surface-900);
    order: 0;
  }

  &_action {
    width: 16.6667%;
    justify-content: flex-end;
    display: flex;
  }
  
}

