$swatch-size: 40px;
$swatch-border-size: 1px;
$swatch-border-radius: 4px;
$borderRadiusFull: 50%;
$kit-designer-preview-size: 164px;

.kit-edit {
  position: relative;
  cursor: pointer;

  .edit-badge {
    display: inline-flex;
    position: absolute;
    right: -0.5rem;
    bottom: -0.5rem;
    padding: 0.5rem;
    text-align: center;
    background-color: var(--surface-200);
    border-radius: $borderRadiusFull;
    box-shadow: $elevation-0;
    transition: background-color $transitionDuration ease-in-out, box-shadow $transitionDuration ease-in-out;

    &:hover {
      background-color: var(--surface-200);
      box-shadow: $elevation-2;
    }

    > .icon {
      color: var(--text-color-light);
    }
  }

  // form segment wrapping kit edit.
  &_wrap {
    display: flex;
    align-items: center;
  }
}

.kit-preview {
  position: relative;
  width: $kit-designer-preview-size;
  height: $kit-designer-preview-size;
  margin: 0 auto;
  text-align: center;
  border-radius: 8px;overflow: hidden;
  border: 1px solid var(--border-color);

  &-empty {
    background-color: #252525;
  }

  &-error {
    display: flex;
    align-items: center;
    background-color: #252525;

    span {
      line-height: 20px;
      padding: 15px;
    }
  }
  
  &-loading {
    .p-skeleton {
      height: 100% !important;
      width: 100%;
    }
  }
}
 

.kit-editor {
  .form-fields {
    .field {
      padding: 1.25rem 0;
      border-bottom: 1px solid var(--border-color);


      .field-header {
        display: flex;
        justify-content: space-between;
      }

      .field-body {
        padding: 0.5rem 0;
        overflow: hidden;
        overflow-x: auto;

        // scrollbar (won't work firefox)
        &::-webkit-scrollbar {
          margin-top: 0.5rem;
          height: 0.5rem;
          width: 0.5rem;
          background-color: transparent;
          border-radius: $radius-large;
          border: 1px solid var(--border-color);
          background-clip: padding-box;

        }
        &::-webkit-scrollbar-thumb {
          background-color: var(--grey-300);
          border-radius: $radius-large;
        }
      }

      .field-value {
        font-size: 0.875rem;
        color: #999;
      }

      &:last-of-type {
        border: none;
        padding-bottom: 0;
      }
    }
  }

  .swatch-options {
    display: flex;

    &.is-disabled {
      pointer-events: none;
      opacity: .5;
    }
  }

  .swatch {
    flex: none;
    width: $swatch-size;
    height: $swatch-size;
    padding: 0;
    margin-right: 10px;
    border: $swatch-border-size solid #000;
    border-radius: $swatch-border-radius;
    cursor: pointer;
    
    &:last-child {
      margin-right: 0;
    }

    &.is-selected {
      box-shadow: inset 0 0 0 3px #333333;
    }

    .icon {
        display: block;
        height: 100%;
        width: 100%;
        text-align: center;
        line-height: $swatch-size - $swatch-border-size;
    }

    svg {
      > * {
        fill: none;
        stroke-width: $swatch-border-size;
        stroke: #000;
      }
    }
  }
}