// Theme
// CSS Variable Mixin with fallback

@mixin var($customProp, $renderedValue) {
  #{$customProp}: map-get($rootVariables, $renderedValue);
  #{$customProp}: var(unquote('--#{$renderedValue}'));
}


// Responsiveness

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin xs {
  @media screen and (min-width: $xs), print {
    @content;
  }
}

@mixin xs-only {
  @media screen and (min-width: $xs) and (max-width: $sm - 1px) {
    @content;
  }
}

@mixin until-sm {
  @media screen and (max-width: $sm - 1px) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: $sm), print {
    @content;
  }
}

@mixin sm-only {
  @media screen and (min-width: $sm) and (max-width: $md - 1px) {
    @content;
  }
}

@mixin until-md {
  @media screen and (max-width: $md - 1px) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: $md), print {
    @content;
  }
}

@mixin md-only {
  @media screen and (min-width: $md) and (max-width: $lg - 1px) {
    @content;
  }
}

@mixin until-lg {
  @media screen and (max-width: $lg - 1px) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: $lg), print {
    @content;
  }
}

@mixin lg-only {
  @media screen and (min-width: $lg) and (max-width: $xl - 1px) {
    @content;
  }
}

@mixin until-xl {
  @media screen and (max-width: $xl - 1px) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: $xl), print {
    @content;
  }
}

@mixin xl-only {
  @media screen and (min-width: $xl) and (max-width: $xxl - 1px) {
    @content;
  }
}

@mixin until-xxl {
  @media screen and (max-width: $xxl - 1px) {
    @content;
  }
}

@mixin xxl {
  @media screen and (min-width: $xxl), print {
    @content;
  }
}

/*

A mixin to generate the classes for a React CSSTransition.

**Example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`

**@params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
@mixin react-transition(
  $name,
  $properties,
  $duration: 100ms,
  $easing: ease,
  $delay: 0,
  $before: "",
  $after: ""
) {
  @include each-prop($properties, 2);
  @include react-transition-phase(
    $name, "enter", $properties,
    $duration, $easing, $delay,
    $before, $after
  );
  @include react-transition-phase(
    $name, "exit", $properties,
    $duration, $easing, $delay,
    $before, $after
  );
}

/*
A mixin to generate the classes for ONE phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
*/
@mixin react-transition-phase(
  $name,
  $phase,
  $properties,
  $duration: 100ms,
  $easing: ease,
  $delay: 0,
  $before: "",
  $after: ""
) {
  $start-index: 1;
  $end-index: 2;

  @if ($phase == "exit") {
    $start-index: 2;
    $end-index: 1;
  } @else if ($phase != "enter" and $phase != "appear") {
    @error "Unknown transition phase: #{$phase}. Expected appear|enter|exit.";
  }

  #{transition-name($phase, $name, $before, $after)} {
    @include each-prop($properties, $start-index);
  }

  #{transition-name(#{$phase}-active, $name, $before, $after)} {
    @include each-prop($properties, $end-index);
    transition-delay: $delay;
    transition-duration: $duration;
    transition-property: map-keys($properties);
    transition-timing-function: $easing;
  }
}

/*
Given map of properties to values, set each property to the value at the given index.
Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
@mixin each-prop($properties, $index) {
  @each $prop, $values in $properties {
    #{$prop}: nth($values, $index);
  }
}