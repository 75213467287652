.p-button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;

  &.p-button-group-center {
    justify-content: center;
  }
  
  &:not(.p-selectbutton) {
    gap: 0.5rem;

    .p-button:not(.p-splitbutton-menubutton):not(.p-splitbutton-defaultbutton) {
      border-radius: $borderRadius !important;
    }

    .p-button:not(:last-child) {
        border-right: 1px solid var(--border-color)
    }
  }
}