$activeDayBG: rgba($primary-50, 0.75);

.rbc-calendar,
.calendar-wrapper.rbc-calendar {
  // month + day 'show more' element
  .rbc-month-row,
  .rbc-day-slot {
    button.rbc-button-link.rbc-show-more {
      margin: 0.25rem 0.5rem 0 0.5rem;
      width: calc(100% - 0.75rem);
      padding: 0.375rem 0.5rem;
      font-family: $family-primary;
      text-align: left;
      color: var(--text-color-light);
      font-weight: 600;
      font-size: 0.75rem;
      background-color: transparent;
      border-radius: $radius-small;
      box-shadow: $elevation-0;
      transition: background-color $transitionDuration ease-in-out, box-shadow $transitionDuration ease-in-out;

      &:hover {
        box-shadow: $elevation-2;
        background-color: var(--surface-200);
      }
    }
  }

  // Month View
  .rbc-month-view {
    background-color: var(--surface-0);

    .rbc-row.rbc-month-header {
      background-color: var(--surface-200);
      box-shadow: inset 0px -1.5px 0px #CBD5E1;

      .rbc-header {
        padding: 0.5rem 1.5rem;
        font-size: 0.75rem;
        font-weight: 500;
        color: var(--text-color);
        text-transform: uppercase;
        letter-spacing: $letter-spacing-large;
        border: none;
      }
    }

    .rbc-month-row {
      &:nth-of-type(n + 2) {
        border-color: var(--border-color);
      }

      .rbc-day-bg {
        border-color: var(--border-color);

        // current date
        &.rbc-today {
          background-color: $activeDayBG;
        }

        // out of range date
        &.rbc-off-range-bg {
          background-color: var(--surface-100);
        }
      }

      .rbc-row-content {
        .rbc-row {
          &:nth-of-type(2) {
            margin-top: -0.25rem;
          }
        }

        .rbc-date-cell {
          padding: 0;
          margin: 0.25rem;

          &.rbc-now.rbc-current {
            button.rbc-button-link {
              color: var(--primary-invert);
              background-color: var(--primary);
              border-radius: 50%;
            }
          }

          // out of range date
          &.rbc-off-range {
            button.rbc-button-link {
              color: var(--text-color-light);
            }
          }

          // Cell date number
          button.rbc-button-link {
            font-family: $family-primary;
            color: var(--text-color-light);
            font-weight: 500;
            font-size: 0.75rem;
            padding: 0.25rem;
          }
        }
      }
    }
  }

  // Week View
  .rbc-time-view {
    .rbc-time-header {
      background-color: var(--surface-200);

      .rbc-row.rbc-time-header-cell {
        min-height: unset;
        background-color: var(--surface-200);
        box-shadow: inset 0px -1.5px 0px #CBD5E1;

        .rbc-header {
          display: inline-flex;
          justify-content: center;
          color: var(--text-color);
          padding: 0.5rem 1.5rem;
          font-size: unset;
          border: none;

          &.rbc-today {
            background-color: unset;
          }

          button.rbc-button-link {
            line-height: 1;

            span {
              font-size: 0.75rem;
              font-weight: 500;
              text-transform: uppercase;
              letter-spacing: $letter-spacing-large;
              font-family: $family-primary;
            }
          }
        }
      }

      // all day events
      .rbc-allday-cell {
        background-color: var(--surface-0);

        .rbc-day-bg {
          box-shadow: inset 0px -1.5px 0px #CBD5E1;

          &.rbc-today {
            background-color: $activeDayBG;
          }
        }
      }
    }

    .rbc-time-content {
      border-top: 0;

      .rbc-day-slot.rbc-time-column {
        background-color: var(--surface-0);

        // active day
        &.rbc-now.rbc-today {
          background-color: $activeDayBG;

          .rbc-current-time-indicator {
            height: 0.125rem;
            background-color: $primary-500;
          }
        }
      }

      .rbc-events-container {
        .rbc-event.event-item_wrapper {
          .rbc-event-content {
            display: flex;
            align-items: center;
          }
        }
      }

      // times column
      .rbc-time-gutter.rbc-time-column {
        background-color: var(--surface-200);

        .rbc-timeslot-group {
          flex-flow: unset;
          align-items: center;
          justify-content: center;

          &:first-of-type {
            border-top: 1px solid var(--border-color);
          }

          .rbc-time-slot {
            display: flex;
            flex: none;

            .rbc-label {
              font-size: 0.625rem;
              padding: 0.375rem;
            }
          }
        }
      }
    }
  }

  // Agenda View
  .rbc-agenda-view {
    .rbc-agenda-content table.rbc-agenda-table {

      // agenda view row headers
      .rbc-agenda-date-cell {
        font-size: 0.75rem;
        font-family: $family-primary;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--text-color-light);
        letter-spacing: $letter-spacing-large;
        text-align: center;
        padding: 0.5rem 1rem;
        vertical-align: middle;
        background-color: var(--surface-200);
        box-shadow: inset -1.5px 0 0 #CBD5E1;
      }

      tbody {
        > tr:last-of-type {
          border-bottom-left-radius: $radius-large;
        }

        > tr > td {
          vertical-align: middle;

          // over-ride prime border double up
          &+ td {
            border: none;
          }
        }
      }

      // agenda view event single
      // TODO: think this is breaking legend columns??
      .rbc-agenda-event-cell {
        padding: 0.5rem;
        background-color: var(--surface-0);

        .rbc-event {
          display: inline-block;
          width: unset;
        }
      }
    }

    .rbc-agenda-empty {
      padding: 4rem;
    }
  }

  // selected cells
  // month view selecting
  .rbc-month-view .rbc-month-row .rbc-row-bg {
    .rbc-day-bg.rbc-selected-cell {
      background-color: var(--surface-200);
      animation-name: selectedCellKF;
      animation-duration: 3000ms;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
  // week view selecting
  .rbc-day-slot.rbc-time-column.rbc-slot-selecting {
    .rbc-slot-selection {
      background-color: var(--border-color);
      padding: 0.5rem;
      font-family: $family-primary;
      font-size: 0.75rem;
      font-weight: 500;
      color: var(--text-color-light);
      border-radius: $radius-small;
      box-shadow: $elevation-2;

      animation-name: selectedCellKF;
      animation-duration: 3000ms;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
}

// toolbar styling specific to calendar
.calendar-wrapper.rbc-calendar {
  > .p-toolbar {
    .p-toolbar-group-left {
      flex: 1;

      .toolbar-title {
        padding-left: 1rem;
        text-align: center;
      }
    }

    .p-toolbar-group-right {
      align-items: unset;

      .p-divider {
        padding: 0;

        &.p-divider-vertical {
          margin: 0 0.5rem;
        }

        &:before {
          border-color: var(--text-color-light);
        }
      }
    }
  }
}

// key-frames for selected cell animation
@keyframes selectedCellKF {
  0% {
    background-color: var(--surface-200);
  }
  25% {
    background-color: $primary-50;
  }
  50% {
    background-color: var(--surface-200);
  }
  75% {
    background-color: $primary-50;
  }
  100% {
    background-color: var(--surface-200);
  }
}