

.app-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 3rem;
}

.app-card {
  flex: 1;

  &-cs {
    .app-card-title-badge {
      background-color: var(--grey-900);
    }
  }

  .p-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;

  }

  .p-card-content {
    flex: 1;
    font-size: 14px;
    line-height: 1.5;
    padding: 0 1rem 0;
    
    > p {
      margin: 0;
    }
  }

  .p-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 0;
  }

  .app-card-title-logo {
    box-shadow: $elevation-1;
    padding: 4px;
    border-radius: $radius;

    &, img {
      vertical-align: top;
    }
  }

  .app-card-title-start,
  .app-card-title-end {
    display: flex;
    gap: 1rem;
  }

  .app-card-title-start {
    align-items: center;
  }

  .app-card-title-end {
    align-items: start;
  }

  .app-card-title-badge {
    font-size: 14px;
  }

  .app-card-title-heading {
    color: var(--text-color-strong);
    margin: 0;
  }

  .app-card-title-caption {
    font-size: 12px;
    font-weight: normal;
    margin: 0;
  }

  .p-card-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  }
}

.product-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .product-cell-logo img {
    vertical-align: top;
  }

  .product-cell-title {
    font-weight: 500;
    margin: 0 0 4px;
  }

  .product-cell-caption {
    font-size: 12px;
    margin: 0;
  }
}


.plan-sidebar {
  max-width: 400px;
  width: 100%;

  .sidebar-header,
  .sidebar-body,
  .sidebar-footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between;
  }

  .plan-sidebar-toolbar {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;

    .form-field {
      height: 100%;
    }

    .form-group {
      min-width: 0;
      max-width: none;
    }

    .plan-sidebar-field-qty {
      flex: 1;

      .p-inputtext {
        width: 100%;
        text-align: center;
      }
    }

    .plan-sidebar-field-billing {
      flex: 3;
    }
  }

  .product-item {
    padding: 0;
    margin: 0;
    box-shadow: none;
    border: none;

    .product-item-header {
      display: none;
    }
  }

  .plan-sidebar-summary {
    margin-bottom: 1rem;
  }
  
  .plan-sidebar-action .p-button {
    width: 100%;
  }
}