.notes-wrapper {
    display: flex;
    height: calc(100vh - 64px);
    border-top: 1px solid var(--border-color-light);
}

.notes-sidebar {
    width: 100%;
    border-right: 1px solid #e5e5e5;
    overflow: auto;
    
    @include sm() {
        width: 250px;
    }

    @include md() {
        width: 300px;
    }

    .has-active-note & {
        display: none;

        @include sm() {
            display: block;
        }
    }

    .page-header {
        padding: 1rem;
    }

    .page-header_title {
        margin: 0;
    }
}

.notes-details {
    display: none;
    flex-direction: column;
    flex: 1;
    padding: 1rem;

    .p-toolbar {
        border: none;
        padding: 0;
    }

    @include sm() {
        display: flex;
    }

    .has-active-note & {
        display: flex;
    }

    > .note-meta {
        @include md() {
            display: none;
        } 
    }

}

.note-toolbar {
    margin-bottom: 2rem;

    .back-btn {
        margin-right: 1rem;

        @include sm() {
            display: none;
        }
    }

    .note-meta {
        display: none;

        @include md() {
            display: block;
        }
    }
}
 
.note-meta {
    font-size: 13px;
    padding: 0.5rem 0;
}


.note-composer {
    display: flex;
    flex-direction: column;
    flex: 1;

    .p-inputtext {
        width: 100%;
        padding: 0;
        border: none;
        box-shadow: none;
        outline: none;
    }

    .p-input-title {
        margin-bottom: 1rem;

        .p-inputtext {
            font-size: 32px;
            font-weight: bold;

            @include md() {
                font-size: 44px;
            }
        }
    }

    .p-input-description {
        flex: 1;

        .p-inputtext {
            line-height: 1.5;
            height: 100%;
        }
    }
}


.note-nodes {
    padding-top: 1rem;
}
