$colourCubeSize: 1.5rem;

.member-pane {
  &_section {
    &:nth-of-type(n + 2) {
      margin-top: 1rem;
    }

    &-title {
      color: $textSecondaryColor;
      text-transform: uppercase;
      letter-spacing: $letter-spacing-large;
      margin-bottom: 0.5rem;
    }

    .member-colour {
      background-color: var(--picked-colour);
      height: $colourCubeSize;
      width: $colourCubeSize;
      border-radius: $radius;
      flex-basis: calc(0% + #{$colourCubeSize});
      margin-right: 0.375rem;
      border: 1px solid var(--border-color);

      ~ h4 {
        line-height: $colourCubeSize;
      }
    }
  }

  &_item {
    display: flex;
    flex-wrap: wrap;

    &-title {
      color: $textSecondaryColor;
      margin-bottom: 0.25rem;
      flex-basis: 100%;
    }

    &-value {
      font-weight: 400;
    }
  }
}