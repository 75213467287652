$button-text-color: var(--text-color-strong);

.p-button {
  text-decoration: none;
  white-space: nowrap;

  &.p-button-text {
    color: $button-text-color;

    &:not(:disabled):hover {
      color: $button-text-color;
      background-color: var(--opaque-100);
    }

    &:not(:disabled):active {
      color: $button-text-color;
    }

    &.p-button-plain {
      color: $plainButtonTextColor;

      &:not(:disabled):hover {
        color: $plainButtonTextColor;
      }

      &:not(:disabled):active {
        color: $plainButtonTextColor;
      }
    }
  }
  .p-button-label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}



.p-button.p-button-secondary, 
.p-button-group.p-button-secondary > .p-button, 
.p-splitbutton.p-button-secondary > .p-button, 
.p-fileupload-choose.p-button-secondary {

  &.p-button-outlined {
      background-color: transparent;
      color: var(--text-color);
      border-color: var(--border-color);

      &:not(:disabled):hover {
          background: var(--surface-200);
          color: var(--text-color-strong);
          border-color: var(--border-color-hover);
      }

      &:not(:disabled):active {
          background: var(--surface-200);
          color: var(--text-color-strong);
          border-color: var(--border-color-hover);
      }
  }
}


.p-splitbutton {
  &.p-button-text {
    > .p-button {
      color: $button-text-color;

      &:not(:disabled):hover {
        color: $button-text-color;
      }

      &:not(:disabled):active {
        color: $button-text-color;
      }
    }

    &.p-button-plain {
      > .p-button {
        color: $plainButtonTextColor;

        &:not(:disabled):hover {
          color: $plainButtonTextColor;
        }
  
        &:not(:disabled):active {
          color: $plainButtonTextColor;
        }
      }
    }
  }
}


/*
$borderRadiusStd: 3px;
$borderRadiusFull: 50%;

.rm-theme {

  .p-button-icon-only {
    width: 2.3rem;
  }

  .p-button-small {
    padding: 0.25rem 0.5rem;

    &.p-button-icon-only {
      padding: 0.33rem 0;
      width: 2rem;
    }
  }

  // over-ride always removing border-radius when using button-set and wanting spaced elements within
  .p-buttonset {
    &.set-is-spaced .p-button {
      &:first-of-type {
        border-top-right-radius: $borderRadiusStd;
        border-bottom-right-radius: $borderRadiusStd;
      }

      &:last-of-type {
        border-top-left-radius: $borderRadiusStd;
        border-bottom-left-radius: $borderRadiusStd;
      }

      &:not(:first-of-type),
      &:not(:last-of-type) {
        border-radius: $borderRadiusStd;
      }
    }

    &.set-is-stacked {
      flex-direction: column;

      > .p-button {
        justify-content: center;
        border-radius: $borderRadiusStd;

        &:not(:last-child) {
          border-right: 1px solid #E3E3E4; // apply var later, for now don't use modifier without secondary severity actions.
        }

        .p-button-label {
          flex: none;
        }

        &:nth-of-type(n + 2) {
          margin-top: 0.5rem;
        }
      }
    }

    &:nth-of-type(n + 2) {
      margin-left: 0.5rem;
    }

    &.p-button-rounded {
      border-radius: $borderRadiusFull;
    }
  }

  .button,
  .p-button {

    // text only modifier class
    &.text-only {
      display: flex;

      .button-text {
        text-decoration: underline;
        transition: color $transitionDuration ease-in-out;
      }

      &:hover {
        .button-text {
          color: var(--primary);
        }
      }
    }

    // secondary button override for text-only secondary button
    &.p-button-secondary.p-button-text:not(.ui-button) {
      color: var(--text-color-strong);

      &:hover {
        color: var(--text-color-strong);
      }
    }

    // secondary button override for + outlined
    &.p-button-secondary.p-button-outlined {
      color: var(--text-color-light);
      border-color:  var(--text-color-light);
      background-color: transparent;
      transition:
        color $transitionDuration ease-in-out,
        border-color $transitionDuration ease-in-out,
        background-color $transitionDuration ease-in-out
      ;

      &:hover {
        color: var(--text-color);
        border-color: var(--text-color);
        background-color: var(--opaque-100);
      }
    }

    &.ui-button {
      color: var(--text-color);
      padding: 0.375rem;

      &:enabled:hover {
        color: var(--text-color-light);
      }
    }

    // Add xs modifier
    &.p-button-xs {
      font-size: 0.625rem; // 10px
      padding: 0.25rem 0.5rem; // 4px 8px
    }
  }

  .p-menuitem-row .p-button {
    display: inline-flex;
    padding: 0.25rem 1rem; // 4px 16px
  }

  // fix missing icon in dropdown button
  .p-splitbutton {
    > .p-splitbutton-menubutton {
      position: relative;

      .p-button-icon.pi-chevron-down {
        &:after {
          content: "expand_more";
          position: absolute;
          font-family: "Material Icons";
          font-size: 1.5rem;
          color: var(--text-color-light);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: content $transitionDuration ease-in-out;
        }
      }

      &[aria-expanded='true'] {
        .p-button-icon.pi-chevron-down:after {
          content: "expand_less";
        }
      }
    }
  }

  // remove underline from all a tags.
  a {
    text-decoration: none;
  }
}

.p-tree {
  border: none;
  padding: 0;
}

.p-tree-toggler { 
  &:after {
    content: "arrow_right";
    font-family: "Material Icons";
    font-size: 1.5rem;
  }

  &[aria-label="Collapse"]:after {
    content: "arrow_drop_down";
  }
}


*/


.p-button {
  font-weight: $weight-bold; 
  line-height: 1;
  font-family: $family-secondary;
  letter-spacing: $letter-spacing-large;
  text-transform: uppercase;
  //padding: 8px 16px;
  padding: 0.5rem 1rem;

  .icon {
    font-size: 1.5em !important;
    pointer-events: none;
  }

  &.p-button-icon-only {
    //width: 40px;
    width: 2.5rem;
  }

  .p-button-icon-left {
    //margin-left: -4px;
    margin-left: -0.25rem;
  }

  .p-button-icon-right {
    //margin-right: -4px;
    margin-right: -0.25rem;
  }

  &.p-button-sm {
    //padding: 4px 8px;
    padding: 0.5rem;

    &.p-button-icon-only {
      //width: 28px;
      width: 1.75rem;
      padding: 0;
    }

    .p-button-icon-left {
      //margin-left: -2px;
      margin-left: -0.125rem;
    }

    .p-button-icon-right {
      //margin-right: -2px;
      margin-right: -0.125rem;
    }
  
  }

  &.p-button-lg {
    //padding: 16px 24px;
    padding: 1rem 1.5rem;

    &.p-button-icon-only {
      //width: 56px;
      width: 3.5rem;
    }

    .p-button-icon-left {
      //margin-left: -6px;
      margin-left: -0.375rem;
    }

    .p-button-icon-right {
      //margin-right: -6px;
      margin-right: -0.375rem;
    }
  }
}