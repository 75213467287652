.drop-zone {
  position: relative;

  &.is-dragging {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ff66002e; 
      //border: 4px solid #ff6700;
      box-shadow: inset 0 0 0 4px #ff6700;
      border-radius: 4px;
      content: '';
    }

    * {
      pointer-events: none;
    }
  }
}