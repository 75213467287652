.p-tag {
  .icon {
    vertical-align: bottom;
  }

  &.p-tag-outlined {
    background-color: transparent;
    border: 1px solid currentColor;
    color: var(--text-color-strong);
  }
}

.product-tag {
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-color-strong);
  padding: 2px 4px 2px 2px;

  .p-image, 
  .p-image img {
    vertical-align: middle;
  }

  >.icon {
    font-size: 1rem !important;
    padding: 4px;
  }

  .p-image,
  > .icon {
    margin-left: -1px;
  }

  .p-image img, > .icon {
    width: 24px;
    height: 24px;
  }
}