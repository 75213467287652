/// VARS
// icon
$iconSize: 1.75rem;
$iconSettings:
  'FILL' 0,
  'wght' 600,
  'GRAD' 0,
  'opsz' 48;

/// MIXINS
// icon setup
@mixin toastIconSettings {
  font-family: "Material Symbols Outlined";
  font-size: $iconSize;
  top: 0;
  bottom: 0;
  left: 0;
  font-variation-settings: $iconSettings;
}

// severity maps
$info: (
  intentColor: $white,
  iconName: 'info',
  iconColor: $black,
);
$warn: (
  intentColor: var(--warning),
  iconName: 'warning',
  iconColor: $white,
);
$error: (
  intentColor: var(--danger),
  iconName: 'error',
  iconColor: $white,
);
$success: (
  intentColor: var(--success),
  iconName: 'check_circle',
  iconColor: $white,
);
$intents: (
  error: $error,
  info: $info,
  success: $success,
  warn: $warn,
);

.p-toast {
  .p-toast-message {
    background-color: $black;
    color: $white;
    overflow: hidden;
    border: none;

    .p-toast-message-content {
      align-items: center;

      // hide primeicon
      .p-toast-message-icon > path {
        display: none;
      }

      svg {
        color: $white;
      }

      .p-toast-message-text {
        margin: 0;

        .p-toast-summary {
          font-family: "Barlow Semi Condensed";
          font-size: 1rem;
          font-weight: 700;
          letter-spacing: 0.025rem;
          text-transform: uppercase;
          color: $white;
        }
  
        .p-toast-detail {
          margin: 0.25rem 0 0 0;
          font-family: "Inter";
          font-size: 0.875rem;
          color: var(--surface-d);
        }
      }
    }

    // severity specific
    @each $key, $val in $intents {
      &.p-toast-message-#{$key} {
        .p-toast-message-content {
          position: relative;

          .p-toast-message-icon {
            display: block;
          }
  
          &:before {
            content: map-get($val, iconName);
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: map-get($val, intentColor);
            color: map-get($val, iconColor);
            padding: calc($iconSize/2);
            @include toastIconSettings;
          }

          .p-toast-message-text {
            margin-left: calc(0.75rem + $iconSize/2);
          }
        }
      }
    }
  }
}