$max-card-width: 180px;

.card-button-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax($max-card-width, 1fr));
  grid-gap: 1rem;
}

.card-button {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: var(--text-color-strong);
  background-color: var(--surface-0);
  border: 1px solid var(--border-color-light);
  border-radius: $radius-large;
  box-shadow: var(--dropdown-box-shadow);
  cursor: pointer;

  flex: 1 0 100%;

  &.is-selected {
    border-color: $primary-500;
    color: $primary-500;
  }

  &.is-align-center {
    text-align: center;

    .card-button-content > .icon {
      margin: auto;
    }
  }

  &-content {
    flex: 1;
    padding: 1.5rem;
    width: 100%;

    > .icon {
      display: block;
    }
  }

  &-label {
    display: block;
    margin: 0.5rem 0 0;
    color: var(--text-color-strong);
    overflow-wrap: break-word;
  }

  &-description {
    display: block;
    color: var(--text-color);
    margin: 0;
    padding: 0;
  }
}