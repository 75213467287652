.p-inline-message {

  &.p-inline-message-secondary {
    background-color: var(--grey-500);

    &.is-subtle {
      background: var(--opaque-100);
      color: var(--opaque-800);
      border: none;
    }

    .p-inline-message-icon {
        color: inherit; 
    }
  }

  &.p-inline-message-info {
      &.is-subtle {
        background: var(--info-opaque);
        color: var(--blue-900);
        border: none;
      }

      .p-inline-message-icon {
          color: inherit; 
      }
  }

  &.p-inline-message-success {
    &.is-subtle {
      background: var(--success-opaque);
      color: var(--green-900);
      border: none;
    }

    .p-inline-message-icon {
        color: inherit; 
    }
  }

  &.p-inline-message-warn {
    &.is-subtle {
      background: var(--warning-opaque);
      color: var(--yellow-900);
      border: none;
    }

    .p-inline-message-icon {
        color: inherit; 
    }
  }

  &.p-inline-message-error {
    &.is-subtle {
      background: var(--danger-opaque);
      color: var(--danger-900);
      border: none;
    }

    .p-inline-message-icon {
        color: inherit; 
    }
  }
}

.integrated-msg {
  > span:not(.product-tag) {
    margin-right: 0.5rem;
    font-size: 13px;
    font-weight: bold;
  }
}