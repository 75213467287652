
#notifications-menu {
  .p-overlaypanel-content {
    padding: 0;
  }
}

.notifications {
  width: 300px;
  max-height: 500px;
  overflow-y: auto;
}

.notifications-header {
  position: sticky;
  top: 0;
  padding: 0.5rem 1rem;
  background: var(--surface-0);
  border-bottom: 1px solid var(--border-color);
  text-align: center;
}

.notification-item {
  border-bottom:  1px solid var(--border-color);

  &:last-child {
    border: none;
  }

  &.is-unread {
    .notification-link {
      background-color: $primary-50;

      &:hover {
        background-color: $primary-100;
      }
    }
  }
}

.notification-link {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  color: var(--text-color-strong);

  &:hover {
    background-color: var(--surface-100);
  }
}

.notification-img {
  flex: none;
}