.plans-toolbar {
  padding: 0;
  border: none;

  > div {
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    
  }

  &-field--sport {
    width: 100%;

    .p-dropdown {
      width: 100%;
    }

    @include xs() {
      width: 250px;
    }
  }

  &-field--qty {
    width: 100%;

    @include xs() {
      width: 150px;
    }

    .p-inputtext {
      text-align: center;
    }
  }

  &-field--billing {
    width: 100%;

    @include xs() {
      width: 250px;
    }
  }

  .p-dropdown,
  .p-inputtext {
    height: 48px;
  }

  .p-selectbutton {
    .p-button {
      height: 48px;
    }
  }

  .plan-entity {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 48px;
    padding: 0 8px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
  }
}

.plans-submit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;
  min-height: 400px;
  gap: 20px;

}

.plans {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px;
  padding: 30px;
}

.p-skeleton {
  flex: 1;
}

.plans-modal {
  width: 90%;

  &.p-dialog.modal {
    .p-dialog-header {
      background-color: #f6f6f6;
    }
    .p-dialog-content {
      padding: 0;
      background-color: #f6f6f6;
    }
  }
}

.plan-card {
  flex: 1;
  min-width: 300px;

  .p-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .p-card-title {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 20px;
    border-bottom: 1px solid var(--border-color);
    z-index: 1;

  }

  .p-card-content {
    flex: 1;
    padding: 10px 20px 20px;
  }

  .p-card-footer {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 20px;
  }
}

.plan-description {
  color: var(--text-color);
  font-size: 14px;
}

.plan-price {
  padding: 10px 0;
  margin: 20px 0;

  &-value {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.plan-features {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .icon {
      margin-right: 0.5rem;
      color: $primary-500;
    }
  }
}

.products-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  margin: 2rem 0;
}

.product-item {
  display: flex;
  flex-direction: column;

  position: relative;
  flex: 1;
  border: 1px solid #e5e5e5;
  padding: 1rem;
  //gap: 2rem;
  border-radius: 8px;

  background-color: var(--surface-0);
  border: 1px solid var(--border-color-light);
  border-radius: $radius-large;
  box-shadow: var(--dropdown-box-shadow);
  margin: 2rem 0 0;
  min-width: 250px;

  &.has-flag {
    border: 2px solid var(--info);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 50px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 99;

    .product-item-footer .p-button {
      background-color: var(--info);
    }
  }

  .product-item-body {
    flex: 1;
  }

  .product-item-footer {
    margin-top: 3rem;

    .p-button {
      width: 100%;
    }
  }

  .product-item-badge {
    
    position: absolute;
    left: -2px;
    right: -2px;
    background-color: var(--info);
    //width: 100%;
    text-align: center;
    color: #fff;
    bottom: 100%;
    font-size: 12px;
    font-weight: bold;
    border-radius: $radius-large $radius-large 0 0;
    padding: 0 0.5rem;
    height: 32px;
    line-height: 32px;
    white-space: nowrap;
  }
}

.product-item-header {
  text-align: center;
}

.product-item-message {
  margin-top: 0;
  background-color: var(--surface-50);

  .p-message-wrapper {
    padding: 0.5rem;
  }
}

.product-item-title {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;
}

.product-item-description {
  font-size: 14px;
  margin-bottom: 2rem;
}

.product-item-block {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.product-item-subheading {
  margin-bottom: 0.5rem;
}

.product-item-price {
  font-size: 40px;
  font-weight: bold;
  color: var(--text-color-strong);
  letter-spacing: -0.05rem;
  margin: 1rem 0;
}

.product-item-interval {
  font-size: 24px;
}

.product-item-discount {
  font-size: 14px;
  color: var(--danger);
  text-decoration: line-through;
}



.product-item-billing {
  font-size: 14px;
}


.addon-card {
  position: relative;
  display: flex;
  border: 1px solid #e5e5e5;
  padding: 1rem;
  gap: 1rem;
  border-radius: 4px;

  .product-tag {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &.is-selected {
    border-color: #ff6700;
  }

  &.is-disabled {
    color: #ccc;
  }
}

.addon-checkbox { 
  &,
  .p-checkbox-box {
    width: 32px;
    height: 32px;

    .p-checkbox-icon.p-icon {
      width: 20px;
      height: 20px;
    }
  }
}

.addon-desc {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.price-options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.price-card {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 2px solid #e5e5e5;
  border-radius: 4px;
  gap: 1rem;
  font-size: 16px;
  font-weight: 700;
  color: var(--text-color-strong);

  &.is-selected {
    border-color: #ff6700;
    font-size: 24px;
    background-color: #FEF1E6;
  }

  &.is-disabled {
    color: #ccc;
  }

  &.has-flag {
    border-color: var(--info);

    &.is-selected {
      background-color: rgb(242, 242, 255);
    }

    .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
      border-color: var(--info);
    }

    .p-radiobutton .p-radiobutton-box.p-highlight {
      border-color: var(--info);
      background-color: var(--info);
    }

  }

  .price-flag {
    position: absolute;
    top: -8px;
    padding: 0 8px;
    font-size: 9px;
    left: 1rem;
    background-color: var(--info);
    color: #fff;
    border-radius: 4px;
    line-height: 16px;
    height: 16px;
    white-space: nowrap;
  }

  .price-details {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }

  .price-discount {
    font-weight: normal;
    font-size: 12px;
    margin-right: 0.5rem;
    opacity: 0.7;
    text-decoration: line-through;
  }


  span {
    vertical-align: middle;
  }
}

.plans-bar {
  position: fixed;
  bottom: 1rem;
  width: 80%;
  left: 10%;
  background-color: var(--surface-0);
  color: var(--text-color-strong);
  border-radius: 8px;
  z-index: 999;

  &-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  &-details {
    border-bottom: 1px solid var(--border-color-light);
    padding: 1rem;

    .list-item {
      background-color: var(--surface-50);
      margin-bottom: 0.5rem;
    }
  }
}

.plans-bar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.plans-bar-total {
  font-size: 18px;
  font-weight: bold;
}

.plan-comparison {  

  > h1 {
    text-align: center;
    margin-bottom: 2rem;
  }

  .p-tabmenu-nav {
    justify-content: center;
  }

  .p-tabmenuitem {
    margin-bottom: 0;
    filter: grayscale(100%);
    opacity: 0.8;

    &:hover {
      filter: none;
    }

    &.p-highlight {
      filter: none;
      opacity: 1;
    }
  }
}

.feature-card {
  min-width: 300px;
  max-width: 400px;

  &-video,
  &-img {
    max-width: 100%;
    height: auto;
    margin-bottom: 0.5rem;
  }

  &-desc {
    margin-bottom: 0;
  }
}