/// Padding of an input field, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
/// @group form
$inputPadding: 0.75rem 0.75rem;

/// Background of an input field
/// @group form
$inputBg: var(--surface-100);

/// Font size of an input field
/// @group form
$inputTextFontSize: 1rem;

/// Text color of an input field
/// @group form
$inputTextColor: $textColor;

/// Color of an icon inside an input field
/// @group form
$inputIconColor: $textColor;

/// Border of an input field
/// @group form
$inputBorder: 1px solid var(--border-color);

/// Border of an input field in hover state
/// @group form
$inputHoverBorderColor: $primaryColor;

/// Border of an input field in focus state
/// @group form
$inputFocusBorderColor: $primaryColor;

/// Color of an input field in invalid state
/// @group form
$inputErrorBorderColor: var(--danger);

/// Text color of a placeholder
/// @group form
$inputPlaceholderTextColor: var(--placeholder-color);

/// Background of a filled input
/// @group form
$inputFilledBg: var(surface-100);

/// Background of a filled input in hover state
/// @group form
$inputFilledHoverBg: var(surface-100);

/// Background of a filled input in focus state
/// @group form
$inputFilledFocusBg: var(surface-100);

/// Backgroud color of an input group addon
/// @group form
$inputGroupBg: var(surface-300);

/// Text color of an input group addon
/// @group form
$inputGroupTextColor: $textSecondaryColor;

/// Minimum width of an input group addon
/// @group form
$inputGroupAddOnMinWidth: 2.357rem;

/// Background of an input list such as dropdown, listbox, multiselect
/// @group form
$inputListBg: var(--dropdown-background);

/// Text color of an input list
/// @group form
$inputListTextColor: $textColor;

/// Border of an input list
/// @group form
$inputListBorder: $inputBorder;

/// Padding of an input list
/// @group form
$inputListPadding: 0.5rem 0;

/// Padding for an individual itrem of an input list
/// @group form
$inputListItemPadding: 0.5rem 1rem;

/// Background for an individual itrem of an input list
/// @group form
$inputListItemBg: transparent;

/// Text color for an individual itrem of an input list
/// @group form
$inputListItemTextColor: $textColor;

/// Hover state background for an individual itrem of an input list
/// @group form
$inputListItemHoverBg: var(--surface-200);

/// Hover state text color for an individual itrem of an input list
/// @group form
$inputListItemTextHoverColor: $textColor;

/// Focus state text color for an individual itrem of an input list
/// @group form
$inputListItemTextFocusColor: $textColor;

/// Focus state background for an individual itrem of an input list
/// @group form
$inputListItemFocusBg: var(--surface-100);

/// Border for an individual itrem of an input list
/// @group form
$inputListItemBorder: 0 none;

/// Border radius for an individual itrem of an input list
/// @group form
$inputListItemBorderRadius: 0;

/// Margin for an individual itrem of an input list
/// @group form
$inputListItemMargin: 0;

/// Box shadow for an individual itrem of an input list in focused state
/// @group form
$inputListItemFocusShadow: inset 0 0 0 0.15rem $focusOutlineColor;

/// Padding for a header of an input list
/// @group form
$inputListHeaderPadding: 0.5rem 1rem;

/// Margin for a header of an input list
/// @group form
$inputListHeaderMargin: 0;

/// Background for a header of an input list
/// @group form
$inputListHeaderBg: var(--surface-200);

/// Text color for a header of an input list
/// @group form
$inputListHeaderTextColor: $textColor;

/// Border for a header of an input list
/// @group form
$inputListHeaderBorder: 0 none;

/// Background for an overlay of an input such as autocomplete or dropdown
/// @group form
$inputOverlayBg: $inputListBg;

/// Background for an overlay header of an input such as autocomplete or dropdown
/// @group form
$inputOverlayHeaderBg: $inputListHeaderBg;

/// Border for an overlay of an input such as autocomplete or dropdown
/// @group form
$inputOverlayBorder: 1px solid var(--dropdown-border-color);

/// Shadow for an overlay of an input such as autocomplete or dropdown
/// @group form
$inputOverlayShadow: var(--dropdown-box-shadow);

/// Width of a checkbox
/// @group form
$checkboxWidth: 20px;

/// Height of a checkbox
/// @group form
$checkboxHeight: 20px;

/// Border of a checkbox
/// @group form
$checkboxBorder: 2px solid var(--border-color);

/// Size of a checkbox icon
/// @group form
$checkboxIconFontSize: 14px;

/// Border color of a selected checkbox
/// @group form
$checkboxActiveBorderColor: $primaryColor;

/// Background of a selected checkbox
/// @group form
$checkboxActiveBg: $primaryColor;

/// Icon color of a selected checkbox
/// @group form
$checkboxIconActiveColor: var(--primary-invert);

/// Background of a selected checkbox in hover state
/// @group form
$checkboxActiveHoverBg: $primaryDarkerColor;

/// Icon color of a selected checkbox in hover state
/// @group form
$checkboxIconActiveHoverColor: var(--primary-invert);

/// Border color of a selected checkbox in hover state
/// @group form
$checkboxActiveHoverBorderColor: $primaryDarkerColor;

/// Width of a radiobutton
/// @group form
$radiobuttonWidth: 20px;

/// Height of a radiobutton
/// @group form
$radiobuttonHeight: 20px;

/// Border of a radiobutton
/// @group form
$radiobuttonBorder: 2px solid var(--border-color);

/// Font size of a radiobutton icon
/// @group form
$radiobuttonIconSize: 12px;

/// Border color of a selected radiobutton
/// @group form
$radiobuttonActiveBorderColor: $primaryColor;

/// Background of a selected radiobutton
/// @group form
$radiobuttonActiveBg: $primaryColor;

/// Icon color of a selected radiobutton
/// @group form
$radiobuttonIconActiveColor: var(--primary-invert);

/// Background of a selected radiobutton in hover state
/// @group form
$radiobuttonActiveHoverBg: $primaryDarkerColor;

/// Icon color of a selected radiobutton in hover state
/// @group form
$radiobuttonIconActiveHoverColor: var(--primary-invert);

/// Border color of a selected radiobutton in hover state
/// @group form
$radiobuttonActiveHoverBorderColor: $primaryDarkerColor;

/// Width of a color picker preview element
/// @group form
$colorPickerPreviewWidth: 2rem;

/// Height of a color picker preview element
/// @group form
$colorPickerPreviewHeight: 2rem;

/// Background of a color picker
/// @group form
$colorPickerBg: var(--grey-700);

/// Border color of a color picker
/// @group form
$colorPickerBorder: 1px solid var(--grey-800);

/// Handle color of a color picker
/// @group form
$colorPickerHandleColor: var(--surface-0);

/// Font size of a rating icon
/// @group form
$ratingIconFontSize: 1.143rem;

/// Icon color for the cancel icon of a rating
/// @group form
$ratingCancelIconColor: var(--danger);

/// Hover icon color for the cancel icon of a rating
/// @group form
$ratingCancelIconHoverColor: var(--danger-hover);

/// Icon color for the star icon of a rating in unselected state
/// @group form
$ratingStarIconOffColor: $textColor;

/// Icon color for the star icon of a rating in selected state
/// @group form
$ratingStarIconOnColor: $primaryColor;

/// Icon color for the star icon of a rating in hover state
/// @group form
$ratingStarIconHoverColor: $primaryColor;

/// Background of a slider
/// @group form
$sliderBg: var(--surface-200);

/// Border of a slider
/// @group form
$sliderBorder: 0 none;

/// Height of a horizontal slider
/// @group form
$sliderHorizontalHeight: 0.286rem;

/// Width of a vertical slider
/// @group form
$sliderVerticalWidth: 0.286rem;

/// Width of a slider handle
/// @group form
$sliderHandleWidth: 1.143rem;

/// Height of a slider handle
/// @group form
$sliderHandleHeight: 1.143rem;

/// Background of a slider handle
/// @group form
$sliderHandleBg: var(--surface-0);

/// Border of a slider handle
/// @group form
$sliderHandleBorder: 2px solid $primaryColor;

/// Border radius of a slider handle
/// @group form
$sliderHandleBorderRadius: 50%;

/// Border of a slider handle in hover state
/// @group form
$sliderHandleHoverBorderColor: $primaryColor;

/// Background of a slider handle in hover state
/// @group form
$sliderHandleHoverBg: $primaryColor;

/// Background color of a range slider
/// @group form
$sliderRangeBg: $primaryColor;

/// Margin of a calendar table
/// @group form
$calendarTableMargin: 0.5rem 0;

/// Margin of a calendar
/// @group form
$calendarPadding: 0.5rem;

/// Background of a calendar
/// @group form
$calendarBg: var(--surface-0);

/// Background of an inlime calendar
/// @group form
$calendarInlineBg: $calendarBg;

/// Text color of a calendar
/// @group form
$calendarTextColor: $textColor;

/// Border of an inline calendar
/// @group form
$calendarBorder: $inputListBorder;

/// Border of an overlay calendar
/// @group form
$calendarOverlayBorder: $inputOverlayBorder;

/// Padding of a calendar header
/// @group form
$calendarHeaderPadding: 0.5rem;

/// Text color of current month and year text in hover state
/// @group form
$calendarMonthYearHeaderHoverTextColor: $primaryColor !default;

/// Background of a calendar header
/// @group form
$calendarHeaderBg: var(--surface-0);

/// Background of an inline calendar header
/// @group form
$calendarInlineHeaderBg: $calendarBg;

/// Border of a calendar header
/// @group form
$calendarHeaderBorder: 1px solid var(--border-color);

/// Text color of a calendar header
/// @group form
$calendarHeaderTextColor: $textColor;

/// Font weight of a calendar header
/// @group form
$calendarHeaderFontWeight: 600;

/// Padding of a calendar weekday cell
/// @group form
$calendarHeaderCellPadding: 0.5rem;

/// Padding of a calendar date cell
/// @group form
$calendarCellDatePadding: 0.5rem;

/// Width of a calendar date cell
/// @group form
$calendarCellDateWidth: 2.5rem;

/// Height of a calendar date cell
/// @group form
$calendarCellDateHeight: 2.5rem;

/// Border radius of a calendar date cell
/// @group form
$calendarCellDateBorderRadius: 50%;

/// Border of a calendar date cell
/// @group form
$calendarCellDateBorder: 2px solid transparent;

/// Background of a calendar date cell in hover state
/// @group form
$calendarCellDateHoverBg: var(--surface-300);

/// Background of a calendar date cell indicating today
/// @group form
$calendarCellDateTodayBg: transparent;

/// Border color of a calendar date cell indicating today
/// @group form
$calendarCellDateTodayBorderColor: var(--primary);

/// Text color of a calendar date cell indicating today
/// @group form
$calendarCellDateTodayTextColor: var(--text-color);

/// Padding of the calendar button bar
/// @group form
$calendarButtonBarPadding: 1rem 0;

/// Padding of a time picker container of a calendar
/// @group form
$calendarTimePickerPadding: 0.5rem;

/// Padding of a time picker element of a calendar
/// @group form
$calendarTimePickerElementPadding: 0 0.429rem;

/// Font size of a time picker element of a calendar
/// @group form
$calendarTimePickerTimeFontSize: 1.286rem;

/// Breakpoint of calendar to apply styles for small screens like phones
/// @group form
$calendarBreakpoint: 769px;

/// Padding of a calendar date cell on small screens like phones
/// @group form
$calendarCellDatePaddingSM: 0;

/// Width of an inputswitch
/// @group form
$inputSwitchWidth: 3rem;

/// Height of an inputswitch
/// @group form
$inputSwitchHeight: 1.75rem;

/// Border radius of an inputswitch
/// @group form
$inputSwitchBorderRadius: 30px;

/// Width of an inputswitch handle
/// @group form
$inputSwitchHandleWidth: 1.25rem;

/// Height of an inputswitch handle
/// @group form
$inputSwitchHandleHeight: 1.25rem;

/// Border radius of an inputswitch handle
/// @group form
$inputSwitchHandleBorderRadius: 50%;

/// Padding of an inputswitch slider
/// @group form
$inputSwitchSliderPadding: 0.25rem;

/// Background color of an inputswitch slider when unselected
/// @group form
$inputSwitchSliderOffBg: var(--opaque-200);

/// Background color of an inputswitch handle when unselected
/// @group form
$inputSwitchHandleOffBg: var(--surface-0);

/// Hover background color of an inputswitch slider when unselected
/// @group form
$inputSwitchSliderOffHoverBg: var(--opaque-300);

/// Background color of an inputswitch slider when selected
/// @group form
$inputSwitchSliderOnBg: $primaryColor;

/// Hover background color of an inputswitch slider when selected
/// @group form
$inputSwitchSliderOnHoverBg: $primaryDarkColor;

/// Background color of an inputswitch handle when selected
/// @group form
$inputSwitchHandleOnBg: var(--surface-0);

/// Height for the progress bar of a fileupload
/// @group form
$fileUploadProgressBarHeight: 0.25rem;

/// Padding of the fileupload content section
/// @group form
$fileUploadContentPadding: 2rem 1rem;

/// Background of an editor toolbar
/// @group form
$editorToolbarBg: var(--surface-100);

/// Border of an editor toolbar
/// @group form
$editorToolbarBorder: 1px solid var(--border-color);

/// Padding of an editor toolbar
/// @group form
$editorToolbarPadding: 1rem;

/// Icon color of an editor toolbar
/// @group form
$editorToolbarIconColor: var(--text-color);

/// Icon color of an editor toolbar in hover state
/// @group form
$editorToolbarIconHoverColor: var(--text-color-hover);

/// Icon color of an editor toolbar in active state
/// @group form
$editorIconActiveColor: $primaryColor;

/// Border of an editor content
/// @group form
$editorContentBorder: 1px solid var(--border-color);

/// Background of an editor content
/// @group form
$editorContentBg: var(--surface-0);

/// Background of a password meter
/// @group form
$passwordMeterBg: var(--grey-500);

/// Background of a week password
/// @group form
$passwordWeakBg: var(--danger);

/// Background of a medium password
/// @group form
$passwordMediumBg: var(--success-light);

/// Background of a strong password
/// @group form
$passwordStrongBg: var(--success-green);
