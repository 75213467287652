.product-grid {
    color: var(--text-color-strong);
    width: 328px;
    padding: 20px 20px;
    border-radius: 8px;
  
    &_list {
      box-sizing: content-box;
      margin: 0;
      padding: 0;
    }
  
    &_item {
      position: relative;
      display: inline-block;
      list-style-type: none;
      padding: 6px;
      vertical-align: top;
    }
  
    &_link {
      display: block;
      width: 84px;
      height: 84px;
      margin: 0;
      padding-top: 4px;
      border-radius: 12px;
      text-align: center;
      text-decoration: none;
      outline: none;
  
      &:hover {
        background-color: $primary-50;
        outline: none;
      }
    }
  
    &_title {
      color: var(--text-color-strong);
      display: inline-block;
      font-size: 14px;
      letter-spacing: .09px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 76px;
      font-weight: 600;
    }
  }