// Import statement (uncomment if needed)
// @import url('https://rsms.me/inter/inter.css');

html {
  font-family: 'Inter', sans-serif;

  @supports (font-variation-settings: normal) {
    font-family: 'Inter var', sans-serif;
  }
}

* {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
}

#react {
  height: 100%;
  width: 100%;
}

.card {
  min-width: 0;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 24px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: var(--background-surface);
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.docs-link {
  font-size: 12px;
  font-weight: 500;
  color: #b062bc;
  text-decoration: none;

  &:hover {
    color: #9846a4;
  }
}

.content {
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #777777;
}

.button-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
}

.button {
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  appearance: none;
  user-select: auto;

  &--main {
    background-color: #b062bc;
    color: #ffffff;

    &:hover {
      background-color: #9846a4;
    }
  }

  &--secondary {
    color: #b062bc;
    background-color: var(--background-surface);
    border: 1px solid #b062bc;

    &:hover {
      color: #9846a4;
      border-color: #9846a4;
    }
  }
}

.formation-container-empty {
  .formation-view_field {
    opacity: 0.3;
    filter: grayscale(1);
  }
}

.formation-view {
  position: relative;
  width: 30vw;
  max-width: 100%;
  height: auto;
}

.formation-view_field {
  width: 100%;
  height: auto;
  vertical-align: top;
}

.formation-empty-msg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.player-card {
  position: absolute; /* Keeps the player card in place */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 23%;
  height: 15%;
  top: 0;
  left: 0;
  overflow: hidden;
  background-image: url('https://cdn.hub.rookieme.com/player_card.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate(-50%, -50%); /* Keeps card centered based on its x/y position */

  &.changed {
    filter: sepia(20%) saturate(70%) brightness(80%) hue-rotate(100deg);
  }

  &.unavailable {
    position: absolute; /* Keeps the card in place and allows the overlay to be positioned on top */
  }

  &.test {
    position: relative; /* Keeps the card in place and allows the overlay to be positioned on top */
  }

  &.bench {
    position: relative; /* Keeps the card in place and allows the overlay to be positioned on top */
  }

  &_content {
    padding: 0 15%;
    font-size: 0.45vw;
    line-height: 1.5;
  }

  &_name {
    font-size: .6vw;
    font-weight: bold;
    color: black;
  }

  /* Overlay to be placed over the image */
  .overlayUnavailable {
    position: absolute;
    top: 15;
    right: 6%;
    width: 88%;
    height: 55%;
    background-color: 'none'; /* Grey transparent fill */
    border: 7px solid black; /* Thick black border */
    z-index: 0; /* Ensures the overlay is on top of the background image */
  }
  .overlayTest {
    position: absolute;
    top: 15;
    right: 6%;
    width: 88%;
    height: 55%;
    background-color: 'none'; /* Grey transparent fill */
    border: 7px solid rgb(235, 216, 4); /* Thick black border */
    z-index: 0; /* Ensures the overlay is on top of the background image */
  }

  .overlayBench {
    position: absolute;
    top: 15;
    right: 6%;
    width: 88%;
    height: 55%;
    background-color: 'none'; /* Grey transparent fill */
    border: 7px solid rgb(255, 103, 0); /* Thick black border */
    z-index: 0; /* Ensures the overlay is on top of the background image */
  }
}

