.page {
  &.view {
    display: flex;
    flex-direction: row;

    .view-inner {
      flex: 1;
      max-width: 100%;
    }
  }
}