.tjc {
    max-width: 640px;
    margin: 3rem auto;
    text-align: center;
}

.tjc-inputs {
    display: flex;
    gap: 0.5rem;
    margin: 2rem 0;

    .p-inputtext {
       width: 100%;
       padding: 1rem 0;
       text-align: center;
    }
}

.tjc-user-type {
    margin: 2rem 0;
    
    .form-label {
        margin-bottom: 0.5rem;
        font-weight: bold;
    }

    .p-selectbutton {
        width: 100%;

        > .p-button {
            flex: 1;
            padding: 1rem;
        }
    }
}