

// Colour Picker
.swatches {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 0.75rem;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  > :not(:first-child) {
    margin-left: 0.75rem;
  }
}

.swatch-option {
  &.p-checkbox,
  &.p-radiobutton {
    width: unset;
    height: unset;
    
    .p-checkbox-box,
    .p-radiobutton-box {
      background: var(--swatch-colour);
      border: 1px solid var(--border-color);
      border-radius: $radius;
      height: 2.25rem;
      width: 2.25rem;
      box-shadow: $elevation-0;

      &:hover {
        background: var(--swatch-colour);
        border-color: var(--text-color-light);
      }

      &.p-highlight {
        border-width: 2px;
        border-color: $primary-500;
        box-shadow: inset 0 0 0 2px #fff;
      }

      .p-radiobutton-icon {
        display: none;
      }
    }
  }

  label {
    display: none;
  }
}

.p-dropdown-value {
  display: flex;
  align-items: center;

  .icon {
    margin-right: 0.5rem;
  }

  &.has-icon-right {
    justify-content: space-between;

    .icon {
      margin-left: 0.5rem;
      margin-right: -0.75rem;
    }
  }
}

.p-datepicker.hide-hours {
  .p-timepicker {
    .p-hour-picker, .p-hour-picker + .p-separator {
      display: none;
    }
  }
}


.p-dropdown-item-group {
  position: sticky;
  top: 0;
  z-index: 99;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.075em;
  border-bottom: 1px solid var(--border-color-light);
}

.p-dropdown-footer {
  border-top: 1px solid var(--border-color-light);
  padding: 0.25rem;

  .p-button {
    width: 100%;
  }
}


/*





$minInputHeight: 48px;
$inputPadding: 0.75rem;

input,
input.p-input,
.form-group > input,
.form-segment > input {
  min-height: $minInputHeight;
  padding: $inputPadding;

  &[type=date] {
    cursor: pointer;
  }

  &.p-disabled,
  &.p-component:disabled {
    opacity: 0.5;
  }
}

.p-dropdown {
  .p-dropdown-label {
    display: flex;
    align-items: center;
    min-height: $minInputHeight;
    padding: $inputPadding;
    padding-right: $inputPadding * 2;
  }

  i.p-dropdown-clear-icon {
    display: none;
  }

  .p-dropdown-trigger {
    &:after {
      content: "expand_more";
      font-family: "Material Icons";
      font-size: 1.5rem;
      color: var(--text-color-light);
      transform: rotate(0deg);
      transition: transform $transitionDuration ease-in-out, color $transitionDuration ease-in-out;
    }

    &[aria-expanded="true"]:after {
      transform: rotate(180deg);
    }

    &:hover:after {
      color: var(--text-color);
    }
  }
}

// fix prime calendar ui icons
.p-datepicker {
  button.p-datepicker-prev,
  button.p-datepicker-next {
    position: relative;

    // configure pseudo element to replace PI
    span.p-datepicker-next-icon:after,
    span.p-datepicker-prev-icon:after {
      position: absolute;
      font-family: "Material Icons";
      font-size: 1.5rem;
      color: var(--text-color-light);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: color $transitionDuration ease-in-out;
    }

    // prev icon name
    span.p-datepicker-prev-icon:after {
      content: "chevron_left";
    }

    // next icon name
    span.p-datepicker-next-icon:after {
      content: "chevron_right";
    }

    // hover style
    span.p-datepicker-prev-icon:hover:after {
      color: var(--text-color);
    }

    // disabled style
    &.p-disabled {
      span.p-datepicker-next-icon:after,
      span.p-datepicker-prev-icon:after {
        color: var(--text-color-disabled);
      }
    }
  }

  // fix disabled display
  .p-yearpicker,
  .p-datepicker-calendar {
    tbody tr td span.p-disabled,
    .p-yearpicker-year.p-disabled {
      opacity: 0.35;
    }
  }
}

// select button groups within form wrappers.
.form .p-selectbutton.p-buttonset {
  flex: 1;
  z-index: 1;

  > .p-button {
    flex: 1;
    min-height: $minInputHeight;
  }
}

*/