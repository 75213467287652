$loadingSpinnerSizeSml: 1.5rem;
$loadingSpinnerSizeMed: 2.5rem;
$loadingSpinnerSizeLrg: 4.5rem;
$loadingSpinnerSizeFull: 6rem;

// values for size types
$sizeSmall: (
  'wrapWidth': unset,
  'wrapHeight': unset,
  'elWidth': $loadingSpinnerSizeSml,
  'elHeight': $loadingSpinnerSizeSml,
);
$sizeMedium: (
  'wrapWidth': unset,
  'wrapHeight': unset,
  'elWidth': $loadingSpinnerSizeMed,
  'elHeight': $loadingSpinnerSizeMed,
);
$sizeLarge: (
  'wrapWidth': unset,
  'wrapHeight': 6rem,
  'elWidth': $loadingSpinnerSizeLrg,
  'elHeight': $loadingSpinnerSizeLrg,
);
$sizeFull: (
  'wrapWidth': 100%,
  'wrapHeight': 100%,
  'elWidth': $loadingSpinnerSizeFull,
  'elHeight': $loadingSpinnerSizeFull,
);

// values for weight types
$lightWeight: ('strokeWeight': .5);
$mediumWeight: ('strokeWeight': 2);
$heavyWeight: ('strokeWeight': 3);

// group all into obj/s to be mapped
$sizeOptions: (
  'small': $sizeSmall,
  'medium': $sizeMedium,
  'large': $sizeLarge,
  'fullscreen': $sizeFull,
);
$weightOptions: (
  'light-weight': $lightWeight,
  'medium-weight': $mediumWeight,
  'heavy-weight': $heavyWeight,
);

// loader wrapper
.loader {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @each $key, $val in $sizeOptions {
      &.is-#{$key} {
        height: map-get($val, wrapHeight);
        width: map-get($val, wrapWidth);
      }
    }

    @each $key, $val in $weightOptions {
      &[data-weight="#{$key}"] svg .p-progress-spinner-circle {
        stroke-width: map-get($val, strokeWeight);
      }
    }

    &.is-overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--opaque-400);
      z-index: 999;
    }
  }
}

// wrapped spinner
.loader-element {
  @each $key, $val in $sizeOptions {
    .loader.is-#{$key} & {
      height: map-get($val, elHeight);
      width: map-get($val, elWidth);
    }
  }

  &.squared svg .p-progress-spinner-circle {
    stroke-linecap: square;
  }
}


// progress spinner
.p-progress-spinner {
  height: $loadingSpinnerSizeMed;
  width: $loadingSpinnerSizeMed;
  margin: 1.5rem;

  circle.p-progress-spinner-circle {
    stroke-width: 0.25rem;
    animation-duration: 2s;
  }

  // modifiers
  &.clear {
    margin: 0;
  }

  &.is-small {
    height: $loadingSpinnerSizeSml;
    width: $loadingSpinnerSizeSml;
  }

  .p-datatable .actions-td &  {
    height: $loadingSpinnerSizeMed + (0.313rem * 2);
    width: $loadingSpinnerSizeMed + (0.313rem * 2);

    &.is-small {
      height: $loadingSpinnerSizeSml + (0.313rem * 2);
      width: $loadingSpinnerSizeSml + (0.313rem * 2);
    }
  }
}

// progress bar
.p-progressbar {
  height: 0.5rem;

  // within table or modal
  .p-datatable &,
  .p-dialog.modal &,
  .form & {
    height: 0.25rem;
    border-radius: 0;
  }
}

.loader .is-default {
  --start-colour: var(--grey-100);
  --end-colour: var(--grey-300);
}
.loader .is-accented {
  --start-colour: var(--grey-50);
  --end-colour: var(--grey-500);
}

@keyframes p-progress-spinner-color-alt {
  from {
    stroke: var(--primary);
  }

  to {
    stroke: var(--primary);
  }
}

.p-progress-spinner-circle {
  stroke: var(--primary) !important;
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite, p-progress-spinner-color-alt 6s ease-in-out infinite !important;
}