.addto-group-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .addto-group-inputs {
    flex: 1;

    .p-selectbutton .p-button {
      flex: 1;
    }

    .list {
      margin: 1rem -1rem;
    }
  }

  .addto-group-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: -1rem;
    border-top: 1px solid var(--border-color);
  }
}
