.report-toolbar {
  padding: 0;
  margin-bottom: 1.5rem;
  min-height: 72px;

  .p-toolbar-group-start,
  .p-toolbar-group-end {
    padding-right: 1rem;
  }


  &.p-toolbar {

    .p-inputwrapper {
        border: none;
        box-shadow: none;
        outline: none;
        min-width: 120px;
    }

    .p-multiselect-label,
    .p-inputtext,
    .p-placeholder {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }

    .p-multiselect-label,
    .p-inputtext {
        font-weight: bold;
    }

    .form-group {
        position: relative;
        border-right: 1px solid #e5e5e5;
    }

    .form-label {
        position: absolute;
        top: 0.75rem;
        left: 0.75rem;
        pointer-events: none;
        z-index: 1;
    }
  }
}

.report-disclaimer {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.75rem;
  padding: 0 0.75rem;
  color: var(--text-color);

  img,
  span {
    vertical-align: top;
  }
}
