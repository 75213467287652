.account-selector {
  height: 100vh;
  width: 100vw;
  background: #f6f6f6;
  overflow: hidden;
}

.account-selector-container {
  max-width: 640px;
  padding: 10px;
  margin: 0 auto;

  .list-item {
    padding: 15px;

    &:not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }

    &:hover {
      background-color: #f6f6f6;
      cursor: pointer;
    }
  }

  .list-item-centered {
    text-align: center;
  }

  .list-item_content-title > * {
    max-width: none;
  }
}

