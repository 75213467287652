.page-entities {
  
  .page-container {

    > .page-header {
      margin: 0;
      height: 80px;

      .page-header_middle--left  {
        overflow: hidden;

        h1 {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .entity-selector {
    position: relative;
    margin: 0 0 2rem;
  
    .entity-selector-inner {
      text-align: center;
      scrollbar-width: none;
      -ms-overflow-style: none;
    
      &::-webkit-scrollbar {
        display: none;
      }
    }

    + .p-button-group {
      margin-bottom: 2rem;
    }
  
    .list-grid-item {
      display: inline-block;
      width: 50%;
      text-align: center;
      padding: 1rem;
      text-decoration: none;
      vertical-align: top;
      cursor: pointer;

      @include md() {
        width: 210px;
        padding: 1.5rem;
      }
  
      .p-avatar {
        width: 120px;
        height: 120px;
        margin-bottom: 1rem;
        border: 1px solid var(--border-color-light);
        border-radius: 8px;

        @include xs() {
          width: 160px;
          height: 160px;
          border-radius: 12px;
        }
      }
    }
  
    .list-grid-item-title {
      font-size: 18px;
      margin: 0 0 0.25rem;
      font-weight: bold;
    }
  
    .list-grid-item-caption {
      margin: 0;
      font-size: 14px;
    }
  }
  
  .entities-widget {
    height: calc(100vh - 144px);
    min-height: 400px;
    display: flex;
    flex-direction: column;
    margin: 0 -1rem;

    @include md() {
      background-color: var(--surface-50);
      border: 1px solid var(--border-color-light);
      border-radius: $radius;
      box-shadow: $elevation-2;
      height: calc(100vh - 200px);
      margin: 0;
    }
  
    .page-header {
      padding: 1rem 1rem 0;
      margin-bottom: 0;
    }

    .p-toolbar {
      background: transparent;
      border: none;

      .p-inputtext {
        height: 32px;
      }
    }
  
    .p-dataview-header {
      padding: 0;
    }

    .p-dataview {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
    }
  
    .p-dataview-content {
      height: 100%;
      scrollbar-width: none;
      -ms-overflow-style: none;
      overflow-y: scroll;
  
      &::-webkit-scrollbar {
        display: none;
      }

      .p-grid {
        height: 100%;
      }
    }

    .p-dataview-emptymessage {
      text-align: center;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .p-dataview-footer {
      border-width: 1px 0 0;
  
      .p-button {
        width: 100%;
      }
    }
  }

  .entity-button-group.card-button-group {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    margin: 1rem 0 3rem;
  
    .card-button-content {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    .card-button-label {
      font-size: 1.15rem;
      font-weight: bold;
    }
  }
}