// Root CSS variables
:root {
  @each $prop, $val in $rootVariables {
      --#{$prop}: #{$val};
  }
}

// Theme specific CSS Variables
@each $theme, $props in $themes {
  .is-theme-#{$theme} {
      @each $prop, $values in $props {
          @each $value in $values {
              --#{$prop}: #{$value};
          }
      }
  }
}

html {
  &.is-size-sm {
    font-size: 14px;
  }

  &.is-size-lg {
    font-size: 18px;
  }
}

body {
  margin: 0;
  background-color: var(--background);
  color: var(--text-color);
  padding: 0;
  margin: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $weight-normal;
  font-family: $family-primary;
}

.app-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.lock-scroll {
  overflow: hidden;
}