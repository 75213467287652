.str-chat__channel {
  width: 100% !important;
}

.str-chat__channel-list {
  height: auto;
}

.str-chat__thread {
  width: 80% !important;
}

.str-chat__channel-header {
  padding: 1rem;
}

.str-chat__message-input {
  padding: 0.5rem 1rem;
}

.chat-wrapper {
  display: flex;
  height: calc(100vh - 64px);
  border-top: 1px solid var(--border-color-light);

  .str-chat {
    --str-chat__message-bubble-background-color: var(--surface-300);
    --str-chat__own-message-bubble-background-color: var(--primary-500);
    --str-chat__own-message-bubble-color: #fff;
  }

  .str-chat__message-text p {
    color: inherit;
  }
}

.chat-sidebar {
  position: relative;
  width: 100%;
  border-right: 1px solid var(--border-color-light);
  overflow: auto;
  
  @include sm() {
      width: 250px;
  }

  @include md() {
      width: 300px;
  }

  .str-chat__channel-preview-messenger:hover {
    background-color: var(--grey-200);
  }

  .str-chat__channel-preview-messenger--active,
  .str-chat__channel-preview-messenger--active:hover {
    background-color: $primary-50;
  }

  .page-header {
      padding: 1rem;
  }

  .page-header_title {
      margin: 0;
  }

  .integrated-msg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
  }
}

.chat-details {
  display: none;
  flex-direction: column;
  flex: 1;

  .p-toolbar {
      border: none;
      padding: 0;
  }

  @include sm() {
      display: flex;
  }

  .has-active-note & {
      display: flex;
  }

  > .note-meta {
      @include md() {
          display: none;
      } 
  }
}




.chat-settings {
  .list {
    width: 100%;
  }

  .form-group {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--border-color-light);
  }
}