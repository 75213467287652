/// Border of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBorder: 1px solid var(--dialog-border-color);

/// Background of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBg: var(--dialog-background);

/// Box shadow of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContainerShadow: var(--dialog-box-shadow);

/// Background of a dialog header
/// @group overlay
$dialogHeaderBg: var(--dialog-background);

/// Border of a dialog header
/// @group overlay
$dialogHeaderBorder: 0px solid var(--border-color);

/// Text color of a dialog header
/// @group overlay
$dialogHeaderTextColor: $panelHeaderTextColor;

/// Font weight of a dialog header
/// @group overlay
$dialogHeaderFontWeight: 600;

/// Font size of a dialog header
/// @group overlay
$dialogHeaderFontSize: 1.25rem;

/// Padding of a dialog header
/// @group overlay
$dialogHeaderPadding: 1rem 1.5rem;

/// Padding of a dialog content
/// @group overlay
$dialogContentPadding: 0 1.5rem 2rem 1.5rem;

/// Border of a dialog footer
/// @group overlay
$dialogFooterBorder: 1px solid var(--border-color);

/// Padding of a dialog footer
/// @group overlay
$dialogFooterPadding: 1.5rem;

/// Padding of a confirmpopup content
/// @group overlay
$confirmPopupContentPadding:$panelContentPadding;

/// Padding of a confirmpopup footer
/// @group overlay
$confirmPopupFooterPadding:0 1rem 1rem 1rem;

/// Background of a tooltip
/// @group overlay
$tooltipBg: var(--tooltip-background);

/// Text color of a tooltip
/// @group overlay
$tooltipTextColor: var(--tooltip-text-color);

/// Padding of a tooltip
/// @group overlay
$tooltipPadding: 0.5rem;