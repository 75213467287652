.p-tooltip {
  max-width: 480px;
}

.p-tooltip .p-button .icon {
  pointer-events: none !important;
}

.p-tooltip .p-button, 
.p-tooltip .p-button:hover {
  background-color: transparent;
  border: none;
  color: #ff6700 !important;
  opacity: 1;
}

.p-tooltip {
  .tooltip-info {
    position: absolute;
    bottom: 100%;
    background: #000000d4;
    width: 300px;
    left: 50%;
    border-radius: 4px;
    padding: 8px;
    transform: translateX(-50%);

    background: $tooltipBg;
    color: $tooltipTextColor;
    padding: $tooltipPadding;
    box-shadow: $inputOverlayShadow;
    border-radius: $borderRadius;

    font-size: 14px;
  }
}