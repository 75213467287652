.column-popover {
  max-height: 90vh;
  margin: 1rem;
  overflow-y: scroll;
  
  .column-popover-empty {
    background-color: #f0f0f0;
  }

  .list-header {
    margin: 0;
    background: #fff;
    line-height: 1rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .p-overlaypanel-content {
    padding: 0;
  }

  .p-listbox {
    border: none;

    .p-listbox-list .p-listbox-item {
      padding: 0;
    }
  }

  .p-orderlist {

    .p-orderlist-item {
      padding: 0;

      &.is-disabled {
        background-color: transparent;
        cursor: default;
      }
    }

    .p-orderlist-controls {
      display: none;
      order: 2;
    }

    .list-item-start {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

}
