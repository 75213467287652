.p-avatar {
  overflow: hidden;
  flex: none;

  &-circle {
    border-radius: $radius-rounded;
  }
  
  svg,
  .svg-image {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }

  .p-avatar-text {
    font-weight: $weight-medium;
  }
}

.detail-cell {
  display: flex;
  align-items: center;
  gap: 1rem;

}