.p-dialog {
  max-width: 100%;
}

.p-dialog.p-confirm-dialog {
  .p-confirm-dialog-message {
    margin-left: 0;
  }
}

.p-confirm-dialog {
  max-width: 520px;
}