.bordered-container {
  padding: 0.75rem;
  border: 1px solid var(--border-color-light);
  border-radius: $radius-small;
}

.p-buttonset {
  display: flex;

  .actions-td & {
    justify-content: flex-end;
  }
}