.form-group.team-join-field,
.form-segment.team-join-field {
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
  margin-top: 20px;

  .form-field {
    align-items: center;

    .form-label {
      margin-right: 10px;
    }
  }

  .title {
    margin-bottom: 15px;
  }

  > div {
    margin-bottom: 15px;
  }

  .form-message {
    width: 100%;
  }
}

.team-join-btn {
  flex-direction: column;
  align-items: flex-start;

  .title {
    margin-bottom: 4px;
  }
}