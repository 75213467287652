$radiusDiff: 0.188rem; // 3px

.form-message {
  position: relative;
  overflow: hidden;
  justify-content: flex-start;
  padding: 0.25rem 0.5rem;
  margin-top: 0.25rem;

  &.message-floated {
    margin-top: -$radiusDiff;
    padding: calc(0.25rem + #{$radiusDiff}) 0.5rem 0.25rem 0.5rem;
    z-index: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:before {
    //content: '';
    position: absolute;
    background-color: var(--danger);
    width: 0.25rem;
    top: 0;
    left: 0;
    bottom: 0;
  }

  // error variant
  &--error,
  &--error.p-inline-message.p-inline-message-error {
    background-color: var(--danger-opaque);
  }

  // icon
  .icon {
    color: var(--danger);
    padding-right: 0.125rem;
    font-weight: 500;

    &.material-symbols-outlined {
      font-variation-settings: 'wght' 600;
    }
  }

  // message
  .p-inline-message-text {
    color: var(--danger);
    font-size: 0.875rem; // 14px
    font-weight: 500;
  }
}