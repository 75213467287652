// Border Radius

$radius-small: 2px !default;
$radius: 4px !default;
$radius-large: 6px !default;
$radius-rounded: 290486px !default;

// Elevation

$elevation-0: none !default;
$elevation-1: 0 0 0 1px rgba($black, 0.1) !default;
$elevation-2: 0 0.25em 0.5em -0.125em rgba($black, 0.1), 0 0 0 1px rgba($black, 0.05) !default;
$elevation-3: 0 0.5em 1em -0.125em rgba($black, 0.1), 0 0 0 1px rgba($black, 0.05) !default;

// Spacing

$spacing-1: 0.125rem; // 2px
$spacing-2: 0.25rem; // 4px
$spacing-3: 0.5rem; // 8px
$spacing-4: 1rem; // 16px
$spacing-5: 1.5rem; // 24px
$spacing-6: 2rem; // 32px
$spacing-7: 2.5rem; // 40px
$spacing-8: 3rem; // 48px

$layout-1: 1rem; // 16px
$layout-2: 1.5rem; // 24px
$layout-3: 2rem; // 32px
$layout-4: 3rem; // 48px
$layout-5: 4rem; // 64px
$layout-6: 6rem; // 96px
$layout-7: 10rem; // 160px

// Responsive Breakpoints

$xs: 400px !default;
$sm: 600px !default;
$md: 768px !default;
$lg: 1024px !default;
$xl: 1280px !default;
$xxl: 1520px !default;
