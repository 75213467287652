.layout-topbar {

  .topbar-menu {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
  
    > .p-button {
      justify-content: center;
      width: 48px;
      height: 48px;
      padding: 0;
  
      .p-skeleton {
        flex: none;
      }
    }
  }
    
  .entity-menu {
    display: flex;
    align-items: center;

    @include lg() {
      display: none;
    }

    > .p-button {
      width: 48px;
      height: 4px;
      justify-content: center;
    }

    .entity-switcher {
      max-width: 200px;
      padding: 0;
    }
  }
}

