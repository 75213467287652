.form {
  position: relative;
  max-width: 40rem;
}

.form-fields {
  display: grid;
  grid-template-columns: [start] 1fr [half] 1fr [full]; 
  gap: 24px 16px;

  &.is-row {
    .form-group {
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      label { 
        flex: 1;
      }
    }
  }

  &.is-divider {
    .form-group {
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 1.5rem;
    }
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  grid-column: start / full;

  &.is-fullWidth {
    grid-column: start / full
  }

  @include md {
    &.is-halfLeft {
      grid-column: start / half;
    }
    
    &.is-halfRight {
      grid-column: half / full;
    }
  }
}

.form-field {
  position: relative;
  display: flex;

  > .p-inputtext,
  > .p-dropdown,
  > .p-selectbutton,
  > .p-calendar {
    width: 100%;
  }

  > .p-selectbutton .p-button {
    flex: 1;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    
    .p-button-label {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 2rem;
}

.form-actions-start, 
.form-actions-center, 
.form-actions-end {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.form-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($white, 0.5);
  z-index: 1;

  .modal & {
    top: -$dialogContentPadding;
    right: -$dialogContentPadding;
    left: -$dialogContentPadding;
  }

  .p-progressbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}




.p-dialog {
  &.is-form {
    .p-dialog-content {
      padding: 0;
    }
  }

  form {
    min-width: 400px;
  }

  .form-fields {
    padding: $dialogContentPadding;
  }

  .form-actions {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: -2rem;
    padding: $dialogFooterPadding;
    background-color: var(--dialog-background);
    border-top: 1px solid var(--dialog-border-color);
    z-index: 10;
    margin: 0 -1.5rem -2rem;

    .p-button {
      width: unset;
      flex: none;
    }
  }
}


.report-card {
  display: flex;
  gap: 20px;
  background: var(--background);
  padding: 20px;
  width: 360px;
  border: 1px solid var(--border-color);
  border-radius: $radius;
  color: var(--text-color-strong);
  text-decoration: none;

  &.is-locked {
    background: var(--surface-50);
    pointer-events: none;
  }

  h3 {
    margin: 0;
  }
}

@include until-md() {
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-text {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
    margin: 0;
  }
}

.p-datatable .p-datatable-thead > tr > th {
  font-family: $family-secondary;
  letter-spacing: $letter-spacing-large;
  text-transform: uppercase;
  font-size: 0.825rem;
  font-weight: $weight-bold;
}