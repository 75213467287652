$sidebar-expanded-width: 17.5rem;
$sidebar-collapsed-width: 4.5rem;
$sidebar-zindex: 999;
$overlay-zindex: 990;

.layout-container {
  min-height: 100vh;

  &:before {
    display: block;
    content: '';
    transition: all $duration-normal;

    @include lg() {
      display: none;
    }
  }

  &.layout-menu-active {
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.6);
      z-index: $overlay-zindex;

    }
  }
}

.layout-topbar {
  //position: sticky;
  //top: 0;
  //background: var(--surface-0);
  //-index: 800;
}

.layout-sidebar {
  display: flex;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: $sidebar-expanded-width;
  flex-direction: column;
  background: var(--surface-50);
  border-right: 1px solid var(--border-color-light);
  transition: width $duration-normal, transform $duration-normal;
  transform: translateX(-100%);
  z-index: $sidebar-zindex;
  overflow: hidden;
  box-sizing: content-box;

  .layout-container.layout-sidebar-pinned & {
    width: $sidebar-expanded-width;
  }

  .layout-container.layout-menu-active & {
    transform: translateX(0);
  }

  @include lg() {
    transform: translateX(0);
    
    .layout-container.layout-sidebar-collapsed & {
      width: $sidebar-collapsed-width;

      .p-button-label {
        display: none;

        .layout-sidebar-pinned &,
        .layout-menu-active & {
          display: block;
        }
      }

      .list-item_content,
      .p-menuitem-text {
        opacity: 0;
        transition: opacity $duration-fast;
    
        .layout-sidebar-pinned &,
        .layout-menu-active & {
          opacity: 1;
        }
      }

      .p-badge {
        position: absolute;
        bottom: -2px;
        right: -2px;
        line-height: 1rem;
        height: 1rem;
        min-width: 1rem;
        font-size: 10px;
      }
    }
  }
}

.layout-sidebar + .layout-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include lg() {
    margin-left: $sidebar-collapsed-width;
    transition: margin-left $duration-normal;
    //overflow-x: hidden;

    .layout-container.layout-sidebar-pinned & {
      margin-left: $sidebar-expanded-width;
    }
  }
}

.layout-content {
  flex: 1;
}



.page-container {
  margin: 0 auto;
  padding: 0 1rem;

  &, &.page-container-large {
    max-width: 62.5rem;
  }

  @include lg() {
    padding: 0 2rem;
  }

  &.page-container-narrow {
    max-width: 42.5rem;
  }

  &.page-container-fluid {
    max-width: none;
  }

  &.page-container-full {
    padding: 0;
    max-width: none;
  }
}



.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;  
  margin-top: 1rem;
}

.column {
  flex: 2 2 30rem;
  min-width: 51%;

  &.is-fullWidth {
    flex: 1 1 100%
  }

  &.is-oneHalf {
    flex: 1 1 28.125rem;
    min-width: 0
  }
  
  &.is-oneThird {
    flex: 1 1 15rem;
    min-width: 0
  }
}




.annotated-section {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  gap: 1rem;
  min-width: 0;
}

.annotated-section + .annotated-section {
  border-top: 1px solid var(--border-color-light);
  padding-top: 2rem;
  margin-top: 1rem;
}

.annotation,
.annotation-content {
  min-width: 0;
}

.annotation-content {
  flex: 2 2 30rem;
}

.annotation {
  flex: 1 1 15rem;
  padding: 1rem 1rem 0 0
}

@media (min-width: 48em) {
  .annotation {
      padding-bottom: 1rem
  }
}

.p-dialog {
  .p-column-filter-overlay,
  .p-autocomplete-panel,
  .p-cascadeselect-panel,
  .p-dropdown-panel,
  .p-mention-panel,
  .p-multiselect-panel,
  .p-treeselect-panel {
    background: $inputOverlayBg;
  }
}

.p-overlaypanel:after, .p-overlaypanel:before {
  content: none;
}