// Neutrals

$white: hsl(0, 0%, 100%) !default;
$black: hsl(0, 0%, 3%) !default;

// Primary

$primary-50: hsl(27, 92%, 95%);
$primary-100: hsl(27, 92%, 90%);
$primary-200: hsl(28, 92%, 79%);
$primary-300: hsl(27, 93%, 64%);
$primary-400: hsl(27, 93%, 53%);
$primary-500: hsl(24, 100%, 50%);
$primary-600: hsl(27, 100%, 34%);
$primary-700: hsl(28, 100%, 26%);
$primary-800: hsl(28, 100%, 19%);
$primary-900: hsl(27, 100%, 12%);


// Lightest Theme

$theme-lightest-grey-50:  hsl(0, 0%, 100%);
$theme-lightest-grey-100: hsl(0, 0%, 100%);
$theme-lightest-grey-200: hsl(0, 0%, 96%);
$theme-lightest-grey-300: hsl(0, 0%, 92%);
$theme-lightest-grey-400: hsl(0, 0%, 83%);
$theme-lightest-grey-500: hsl(0, 0%, 74%);
$theme-lightest-grey-600: hsl(0, 0%, 58%);
$theme-lightest-grey-700: hsl(0, 0%, 45%);
$theme-lightest-grey-800: hsl(0, 0%, 31%);
$theme-lightest-grey-900: hsl(0, 0%, 20%);

// Light Theme

$theme-light-grey-50:  hsl(0, 0%, 100%);
$theme-light-grey-100: hsl(0, 0%, 96%);
$theme-light-grey-200: hsl(0, 0%, 92%);
$theme-light-grey-300: hsl(0, 0%, 88%);
$theme-light-grey-400: hsl(0, 0%, 79%);
$theme-light-grey-500: hsl(0, 0%, 70%);
$theme-light-grey-600: hsl(0, 0%, 56%);
$theme-light-grey-700: hsl(0, 0%, 43%);
$theme-light-grey-800: hsl(0, 0%, 29%);
$theme-light-grey-900: hsl(0, 0%, 17%);

// Dark Theme

$theme-dark-grey-50:  hsl(0, 0%, 10%);
$theme-dark-grey-100: hsl(0, 0%, 15%);
$theme-dark-grey-200: hsl(0, 0%, 22%);
$theme-dark-grey-300: hsl(0, 0%, 29%);
$theme-dark-grey-400: hsl(0, 0%, 35%);
$theme-dark-grey-500: hsl(0, 0%, 43%);
$theme-dark-grey-600: hsl(0, 0%, 56%);
$theme-dark-grey-700: hsl(0, 0%, 73%);
$theme-dark-grey-800: hsl(0, 0%, 89%);
$theme-dark-grey-900: hsl(0, 0%, 100%);

// Darkest Theme

$theme-darkest-grey-50:  hsl(0, 0%, 3%);
$theme-darkest-grey-100: hsl(0, 0%, 8%);
$theme-darkest-grey-200: hsl(0, 0%, 15%);
$theme-darkest-grey-300: hsl(0, 0%, 22%);
$theme-darkest-grey-400: hsl(0, 0%, 29%);
$theme-darkest-grey-500: hsl(0, 0%, 36%);
$theme-darkest-grey-600: hsl(0, 0%, 49%);
$theme-darkest-grey-700: hsl(0, 0%, 64%);
$theme-darkest-grey-800: hsl(0, 0%, 78%);
$theme-darkest-grey-900: hsl(0, 0%, 94%);

// Colors

$blue-50: hsl(240, 100%, 95%);
$blue-100:	hsl(240, 100%, 90%);
$blue-200:	hsl(240, 100%, 80%);
$blue-300:	hsl(240, 100%, 70%);
$blue-400:	hsl(240, 100%, 60%);
$blue-500:	hsl(240, 100%, 50%);
$blue-600:	hsl(240, 100%, 40%);
$blue-700:	hsl(240, 100%, 30%);
$blue-800:	hsl(240, 100%, 20%);
$blue-900:	hsl(240, 100%, 10%);

$red-50: hsl(354, 72%, 94%);
$red-100: hsl(352, 76%, 89%);
$red-200: hsl(352, 76%, 77%);
$red-300: hsl(352, 75%, 60%);
$red-400: hsl(352, 75%, 51%);
$red-500: hsl(352, 100%, 43%);
$red-600: hsl(352, 100%, 36%);
$red-700: hsl(352, 100%, 30%);
$red-800: hsl(352, 100%, 24%);
$red-900: hsl(352, 100%, 17%);

$orange-50: hsl(27, 92%, 95%);
$orange-100: hsl(27, 92%, 90%);
$orange-200: hsl(28, 92%, 79%);
$orange-300: hsl(27, 93%, 64%);
$orange-400: hsl(27, 93%, 53%);
$orange-500: hsl(24, 100%, 50%);
$orange-600: hsl(27, 100%, 34%);
$orange-700: hsl(28, 100%, 26%);
$orange-800: hsl(28, 100%, 19%);
$orange-900: hsl(27, 100%, 12%);

$green-50: 	hsl(159, 55%, 94%);
$green-100: hsl(149, 80%, 90%);
$green-200:	hsl(152, 76%, 80%);
$green-300:	hsl(156, 72%, 67%);
$green-400:	hsl(158, 64%, 52%);
$green-500:	hsl(160, 84%, 39%);
$green-600:	hsl(161, 94%, 30%);
$green-700:	hsl(163, 94%, 24%);
$green-800:	hsl(163, 88%, 20%);
$green-900:	hsl(164, 86%, 16%);

$yellow-50: hsl(41, 100%, 95%);
$yellow-100: hsl(40, 100%, 90%);
$yellow-200: hsl(40, 100%, 80%);
$yellow-300: hsl(40, 100%, 65%);
$yellow-400: hsl(40, 100%, 55%);
$yellow-500: hsl(40, 100%, 47%);
$yellow-600: hsl(40, 100%, 43%);
$yellow-700: hsl(40, 100%, 35%);
$yellow-800: hsl(40, 100%, 27%);
$yellow-900: hsl(40, 100%, 20%);
