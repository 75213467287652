.profile-header {

    text-align: center;

    .profile-avatar {
        display: inline-block;
        margin-bottom: 1rem;

        &.p-overlay-badge .p-badge {
            top: auto;
            bottom: 0;
            transform: none;
        }
    }

    .profile-title {
        margin: 0;
    }

    .profile-caption {
        margin: 0 0 1rem;
        font-size: 14px;
    }
}


