.p-datatable { 

  &.p-datatable-gridlines .p-datatable-scrollable-table > .p-datatable-thead {
    top: 0;
  }

  .p-sortable-column {
    
    .p-column-header-content {
      position: relative;
      padding-right: 1.5rem;
    }

    &.p-align-center .p-column-header-content {
      padding-left: 1.5rem;
    }
  
    .p-sortable-column-icon {
      position: absolute;
      right: 0;
      top: 50%; 
      transform: translateY(-50%);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    &:not(.p-highlight):not(.p-sortable-disabled):hover {
      .p-sortable-column-icon {
          opacity: 1;
      }
    }
    
    &.p-highlight {
      .p-sortable-column-icon {
          opacity: 1;
      }
    }
  }

  .p-frozen-column {
    background-color: $tableBodyRowBg;
    z-index: 1;
  }

  .p-rowgroup-header {
    z-index: 2;
    font-weight: bold;
    color: var(--text-strong);

    .is-sticky {
      position: sticky;
      width: 400px;
      left: 1rem;
    }
  }

  .p-datatable-tbody > tr {
    &.is-loading {
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }
  }

  &.p-datatable-striped .p-datatable-tbody > tr.p-row-odd .p-frozen-column {
    background-color: $tableBodyRowEvenBg;
  }

  .p-datatable-thead > tr > th {
    background-color: $tableBodyRowBg;
    color: var(--text-strong);
  }

  &:not(.read-only) .p-datatable-tbody > tr > td {
    &.is-cell-disabled  {
      background-color: var(--surface-200);
    } 

    &.p-editable-column:not(.is-cell-disabled) {
      cursor: pointer;
    }

    &.p-editable-column:not(.is-cell-disabled):hover,
    &.p-cell-editing {
      background-color: var(--primary-50);
    }

    &.p-cell-editing {
      padding: 0.5rem;
    }
  } 
}