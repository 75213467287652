.ticket-message {
  margin: 80px 0;
  text-align: center;
  max-width: 500px;
  width: 100%;

  .p-avatar {
    width: 48px;
    height: 48px;
    margin-bottom: 1rem;
  }
}

.ticket-message-mask {
  position: relative !important;
  height: auto !important;
  width: auto !important;
  animation: none;
  pointer-events: none;
}